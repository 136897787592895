.single-slab{
  width: 100%;
  height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;  
    position: relative;
  
} 
.slabs-list {
  margin-bottom: 1em;
}
  .popup{
    width: 100%;
    margin: 0 auto;
    display: none;
  }
  .popup.open{
    display: block;
  }
 
  
   .iButton{
    position: absolute;
    right: 4%;
    top: 3%;
    font-weight: bold;
    cursor: pointer;
}
.iButton:hover{
      color: white;

  }

.referralBox {
  padding: 1em 0;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color:#1d2129 ;
  color: white;
}
.referralButton {
  display: inline-block;
    padding: 10px 30px;
    background-color: #e62b1e;
    color: #000;
    border-radius: 7px;
    border: 2px solid #e62b1e;
    width: 20%;
    text-align: center;
    text-decoration: none;
    transition: 0.3s all ease;
}
.referralButton {
  color: white;
  outline: none;
  text-decoration: none !important;
}

.referralButton:hover {
  color: white;
  background-color: black;
}

  @media screen and (max-width: 700px) {
    .referralButton {
      width: 70%;
    }
    .back-content{
     
      overflow: visible;
    }
    
  }