@media all and (min-width: 745px) {
    
      .teamList {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        margin-bottom: 1rem;
        padding-bottom: 0.25rem;
      }

  }

.lineTitle img{
    height: 70px;
    /* width: 70px; */
    display: inline;
    margin:-1.5rem 0;
    /* overflow: hidden; */
    filter: drop-shadow(0 0 0 8px black);
    

}



/* 
.teamList{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: 100px;
    justify-content: space-evenly;
    align-items: center;
}
  

.card-l-cen{
    grid-area:b;
}

.card-r-cen{
    grid-area: c;
} */