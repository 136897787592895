.iButtonComp{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 70%;
    height: 60vh;
    border: 2px solid white;
    top: 20%;
    left: 15%;
    z-index: 5;
    background: black;
    border-radius: 15px;
   
    /* margin: 0 auto; */
    

}

.showBox {
    display: block !important;
}
.hideBox {
    display: none;
}
.iButtonComp p {
    font-weight: 700;
    color: white;
    font-size: 2rem;
} 

.iButtonComp-Details{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
}

.iButtonComp-Details img{
    width: 70%;
    filter: grayscale(1)
}

.changeColor{
    color: white !important;
}
.iButtonComp-Details .ticket-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.iButtonComp-Details .ticket-details p {
    font-size: 1.5em;
    margin-top: 1em;
    color: gray;
}
.removeFilter {
    filter: grayscale(0) !important;
}

.displayCont {
    display:  contents !important;
}

.iButtonComp-Close{
    position: absolute;
    top: 0;
    right: 3%;
    font-size: 1.5rem;
    
    cursor: pointer;
    font-weight: bold;
    color: white ;
    z-index: 10;
    
}

@media screen and (max-width: 700px) {
    .iButtonComp{
        width: 90%;
        height: inherit;
        top: 20%;
        left: 5%;
    }
    .iButtonComp-Details{
        flex-direction: column;
    }
    .iButtonComp-Details p:nth-child(1){
        margin-top: 2rem;
    }
    .iButtonComp-Details .ticket-details{
        display: none;
    }
    
    
}