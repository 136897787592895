.sponsor-title{
    width: 100%;
        position: relative;
        
}


.sponsor-title:before {
    content: "";
    display: block;
    width: 30%;
    height: 2px;
    background: #e62b1e;
    left: 10%;
    top: 50%;
    position: absolute;
  }
.sponsor-title:after {
    content: "";
    display: block;
    width: 30%;
    height: 2px;
    background: #e62b1e;
    right: 10%;
    top: 50%;
    position: absolute;
  }

  #AssociateSponsor{
    max-width: 100%;
        position: relative;
  }

  #AssociateSponsor:before {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #e62b1e;
    left: 10%;
    top: 50%;
    position: absolute;
  }
  #AssociateSponsor:after {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #e62b1e;
    right: 10%;
    top: 50%;
    position: absolute;
  }

  #CoSponsor:before{
    width: 30% ;

  }
#CoSponsor:after{
  width: 30% ;
}

.hoverEffect{
  position: relative !important;
  transition: 0.3s all ease-in-out;
  /* z-index: 1000; */
  
}

.hover{
  position: absolute;
  top: 0;
  left: 18%;
  width: 300px;
  height: 300px;
  opacity: 0.9;
  background: black;
  color: white;
  font-weight: bold;

  
  display: flex;
  align-items: center;
  justify-content: center;
  
  
  padding: 20px;
  border-radius: 8px;

  box-sizing: border-box;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.hover p{
  text-align: center;
}

.hoverEffect:hover .hover{
  /* transform: scale(1.03); */
  transform: rotateX(0deg);
}

@media screen and (max-width:900px) {
  .sponsor-title:after{
    width: 22%;
  }
  .sponsor-title:before{
    /* display: none; */
    width: 22%;
}

#AssociateSponsor:before, #AssociateSponsor:after{
  width: 15% !important;
}

#CoSponsor:before,#CoSponsor:after{
  width: 24% !important;
}


  
}

  @media screen and (max-width:700px) {
    
    .responsive{
      width: 100vw !important;
      overflow-x: hidden;
    }
  
    .sponsor-title:after{
        display: none;
    }
    .sponsor-title:before{
        display: none;
    }

    #AssociateSponsor::before, #AssociateSponsor::after{
      display: none;
    }
   
    #CoSponsor:before{
      display: none;
    }
    #CoSponsor:after{
      display: none;
    }

    .hover{
      left: 12%;
    }

    
    
  }