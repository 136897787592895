.submitCont {
  margin: 5em 0;
}

.submitCont .instructions {
  padding: 0 0 0 20px;
  margin: 0 auto 3em;
}

.submitCont .instructions h3 {
  font-weight: 500;
  font-size: 1.5em;
}

.submitCont .instructions ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.submitCont .instructions ol li {
  font-size: 0.9em;
  line-height: 1.6em;
}

.submitCont .instructions ol li a {
  color: #e62b1e;
}

.submitCont .instructions ol li strong {
  font-weight: 600;
}

.submitCont .instructions ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.submitCont .instructions ol li:not(:first-child) {
  margin-top: 0.7em;
}

.submitCont form {
  margin: 3em auto 0;
  width: 90vw;
}

.submitCont form .formRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.submitCont form .formRow:not(:first-child) {
  margin-top: 1.5em;
}

.submitCont form .inputGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.submitCont form .inputGroup #title,
.submitCont form .inputGroup #upload {
  width: 100%;
}

.submitCont form .inputGroup label {
  font-weight: 600;
  margin-bottom: 0.4em;
}

.submitCont form .inputGroup input,
.submitCont form .inputGroup select {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
}

.submitCont form .inputGroup select {
  padding: 9px;
}

.submitCont form .inputGroup button {
  background-color: #fff;
  color: #e62b1e;
  font-weight: 600;
  border: 2px solid #e62b1e;
  padding: 10px 45px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  margin: 2em auto 0;
  outline: none;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.submitCont form .inputGroup button:hover {
  background-color: #e62b1e;
  color: #fff;
}

.submitCont form .inputGroup.progressBar {
  width: 100%;
  margin: 2em auto 0;
}

@media only screen and (min-width: 992px) {
  .submitCont {
    margin: 5em;
  }
  .submitCont .instructions {
    padding: 0 0 0 50px;
    margin-bottom: 3em;
  }
  .submitCont .instructions h3 {
    font-weight: 500;
    font-size: 1.5em;
  }
  .submitCont .instructions ol {
    margin: 1.2em 2em 0;
  }
  .submitCont .instructions ol li {
    font-size: 0.9em;
  }
  .submitCont .instructions ol li:not(:first-child) {
    margin-top: 0.7em;
  }
  .submitCont form {
    width: 75vw;
  }
  .submitCont form .formRow.singleInput {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .submitCont form .formRow.twoInputs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .submitCont form .inputGroup.progressBar {
    width: 50%;
  }
}
/*# sourceMappingURL=SubmitBlogComponent.css.map */