@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter&family=Lugrasimo&family=Pangolin&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:wght@400;600;900&family=Montserrat:wght@400;500;600;700;900&family=Libre+Baskerville:ital@1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Flaticon", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Franklin Gothic Medium", "Arial Narrow", sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Sango-Light";
  src: local("Sango-Light"), url(/static/media/Sango-Light.a369672e.otf) format("truetype");
}

@font-face {
  font-family: "Flaticon";
  src: local("Flaticon"), url(/static/media/Flaticon.9d41344f.ttf) format("truetype");
}

.header-logo {
  margin-left: 30px;
}

.header-logo-img {
  height: 60px;
  width: 100px;
}

@media screen and (max-width: 999px) and (min-width: 750px) {
  .header-logo-img {
    height: 50px;
    width: 80px;
  }
}

.navbar-nav {
  -webkit-align-items: center;
          align-items: center;
}
.nav-btn {
  color: black;
  margin: 0 10px;
  padding: 20px 10px;
}
.navbar-toggler {
  background: #b6b6b6;
}
.nav-btn:hover {
  color: #ff7e5f;
  text-decoration: none;
  transition: 0.5s;
}
.banner_text_iner {
  color: antiquewhite;
}

.page-enter {
  position: fixed;
  opacity: 0.01;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: all 300ms ease-out;
}

.nav-link {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
  white-space: nowrap;
}

/* @media screen and (max-width: 1000px) {
  .nav-link {
    font-size: 16px;
  }
} */

@media screen and (max-width: 992px) and (min-width: 768px) {
  .nav-link {
    font-size: 0.9em;
  }
}

.tedxNavbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  height: 10vh;
  z-index: 999;
}

.tedxNavbar .navbarTop {
  position: relative;
}

.tedxNavbar .navbarTop .mobileNavLogo {
  height: 5vh;
  display: none;
}

.tedxNavbar .navbarTop .navItems {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 10vh;
  margin: 0 2em;
}

.tedxNavbar .navbarTop .navItems img {
  height: 5vh;
}

.tedxNavbar .navbarTop .navItems .navLinks {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.tedxNavbar .navbarTop .navItems .navLinks a {
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
  color: #000;
}

.tedxNavbar .navbarTop .navItems .navLinks a:not(:last-child) {
  margin-right: 4em;
}

.tedxNavbar .navbarTop .navItems .navLinks a::after {
  position: absolute;
  content: "";
  background-color: #e62b1e;
  width: 0;
  height: 3px;
  bottom: -0.4em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: 0.3s all ease;
}

.tedxNavbar .navbarTop .navItems .navLinks a:hover {
  color: #e62b1e;
}

.tedxNavbar .navbarTop .navItems .navLinks a:hover::after {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .tedxNavbar .navbarTop {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
  }
  .tedxNavbar .navbarTop .hamburger {
    position: fixed;
    top: 5vh;
    left: 3%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    z-index: 1001;
  }
  .tedxNavbar .navbarTop .hamburger .line {
    height: 3px;
    background-color: #000;
    margin: 5px;
    transition: 0.3s all ease;
  }
  .tedxNavbar .navbarTop .hamburger .line1.normal {
    width: 30px;
  }
  .tedxNavbar .navbarTop .hamburger .line1.reverse {
    width: 10px;
  }
  .tedxNavbar .navbarTop .hamburger .line2 {
    width: 20px;
  }
  .tedxNavbar .navbarTop .hamburger .line3.normal {
    width: 10px;
  }
  .tedxNavbar .navbarTop .hamburger .line3.reverse {
    width: 30px;
  }
  .tedxNavbar .navbarTop .mobileNavLogo {
    display: block;
    height: 5vh;
  }
  .tedxNavbar .navbarTop .navItems {
    position: fixed;
    margin: 0;
    top: 0;
    left: -100vw;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    z-index: 1000;
    transition: 0.3s all ease;
    overflow: auto;
  }
  .tedxNavbar .navbarTop .navItems img {
    display: none;
  }
  .tedxNavbar .navbarTop .navItems .navLinks {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 100%;
    width: 100%;
  }
  .tedxNavbar .navbarTop .navItems .navLinks a {
    margin: 0 !important;
  }
  .tedxNavbar .navbarTop .navItems.show {
    left: 0;
  }
}
/*# sourceMappingURL=NavbarComponent.css.map */
footer {
  padding: 1.5em 5em;
  background-color: #000;
}

footer .footerTop {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  grid-row-gap: 2em;
  padding-bottom: 1em;
}

footer .footerTop .logo {
  -ms-grid-column-align: start;
      justify-self: start;
  height: 40px;
}

footer .footerTop .email {
  position: relative;
  text-align: center;
  width: 100%;
}

footer .footerTop .email input {
  text-align: left;
  width: 75%;
  background-color: #000;
  border: 1px solid #707070;
  color: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  outline: none;
}

footer .footerTop .email i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 80%;
  font-size: 0.8em;
  color: #fff;
  cursor: pointer;
}

footer .footerTop .social {
  -ms-grid-column-align: right;
      justify-self: right;
}

footer .footerTop .social a {
  color: #fff;
}

footer .footerTop .social a i {
  font-size: 1.5em;
}

footer .footerTop .social a:not(:first-child) {
  margin-left: 2em;
}

footer .footerTop .social a:hover {
  color: #e62b1e;
}

footer hr {
  background-color: #fff;
  opacity: 0.5;
}

footer .footerBottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 1em;
  color: #fff;
  font-size: 0.8em;
}

footer .footerBottom span a {
  color: #e62b1e !important;
  text-decoration: none;
}

footer .footerBottom span a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  footer {
    padding: 1.5em 1.5em;
  }
  footer .footerTop {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  footer .footerTop .logo {
    -ms-grid-column-align: center;
        justify-self: center;
  }
  footer .footerTop .social {
    -ms-grid-column-align: center;
        justify-self: center;
  }
  footer .footerBottom {
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  footer .footerBottom span:last-child {
    margin-top: 1.5em;
  }
}
/*# sourceMappingURL=FooterComponent.css.map */
.homeCover {
  position: relative;
  background-image: url(/static/media/2.3414b894.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  background-position: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
  height: 80vh;
  border-radius: 0% 0% 50% 50%/0% 0% 15% 15%;
}
.homeCover::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}
.homeCover img {
  z-index: 1;
}
.homeCover h1 {
  color: #fff;
  margin-top: 1em;
  text-align: center;
  padding: 0 10px;
  z-index: 1;
}
.homeCover a {
  position: absolute;
  bottom: 5em;
  margin-top: 3em;
  z-index: 1;
  transition: 0.3s all ease;
}
.homeCover a button {
  background-color: #fff;
  color: #000;
  font-weight: 600;
  border: 1px solid #fff;
  padding: 7px 20px;
  border-radius: 7px;
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s all ease;
}
.homeCover a button:hover {
  background-color: #e62b1e;
  border: 1px solid #e62b1e;
  color: #fff;
}
.homeCover a.small {
  position: fixed;
  bottom: 2em;
  right: 2em;
  z-index: 1000;
}
.homeCover a.small button {
  background-color: #e62b1e;
  color: #fff;
  border: 1px solid #e62b1e;
  padding: 10px;
  border-radius: 50%;
  font-size: 1.4em;
  height: 3.5em;
  width: 3.5em;
  z-index: 1000;
}
.homeCover a.small button:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
}

.heading hr {
  width: 90%;
  opacity: 0.4;
  margin: auto;
}
.heading h2 {
  text-align: center;
  font-weight: bold;
  margin: 2em 15px;
  font-size: 2em;
}

.homeContent {
  text-align: center;
  padding: 0 20px;
}

.infoSection {
  margin: 5em 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 5em;
}
.infoSection .singleInfo {
  text-align: center;
}
.infoSection .singleInfo .infoQues {
  font-size: 0.95em;
}
.infoSection .singleInfo .infoAnswer {
  font-weight: 700;
  font-size: 1.1em;
}
.infoSection .singleInfo:hover .infoAnswer {
  color: #e62b1e;
}

.timerSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 3em;
  justify-items: center;
}
.timerSection .singleTimer {
  text-align: center;
  width: -webkit-min-content;
  width: min-content;
}
.timerSection .singleTimer .timer {
  margin: 0.5em 0 0;
  padding: 30px;
  color: #e62b1e;
  font-size: 3em;
  width: 2.5em;
  font-weight: bold;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 15px 15px 31px #d9d9d9, -15px -15px 31px #ffffff;
  border-radius: 10px;
  transition: 0.3s all ease;
}
.timerSection .singleTimer .timer:hover {
  background: linear-gradient(145deg, #cf271b, #f62e20);
  color: #fff;
}

.ctaBtn {
  text-align: center;
}
.ctaBtn button {
  margin: 3em 0 5em;
  padding: 5px 15px;
  background-color: #fff;
  color: #e62b1e;
  border: 2px solid #e62b1e;
  font-size: 1.2em;
  cursor: pointer;
  outline: none;
  transition: 0.3s all ease;
}
.ctaBtn button:hover {
  background-color: #e62b1e;
  color: #fff;
}

.parallaxBg {
  position: relative;
  background-image: url(/static/media/wire-dot-background.e7ac20ca.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
  background-position: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.parallaxBg .content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  padding: 0 5vw;
  z-index: 2;
}
.parallaxBg .content .heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}
.parallaxBg .authorImage {
  -webkit-align-self: end;
          align-self: end;
  padding: 0 5vw 0 0;
  z-index: 2;
}
.parallaxBg .authorImage img {
  height: 35vh;
}
.parallaxBg::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.speakersSection {
  margin: 0 5em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10em;
  grid-row-gap: 3em;
}
.speakersSection img {
  width: 100%;
  transition: 0.3s all ease;
}
.speakersSection img:hover {
  -webkit-filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(0.8);
          filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(0.8);
}

.videosList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3em;
  gap: 3em;
  margin: 3em 20px 6em;
}
.videosList .singleVideo iframe {
  width: 100%;
  height: 250px;
  border-radius: 10px;
}
.videosList .singleVideo .title {
  margin-top: 0.75em;
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .videosList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 992px) {
  .homeContent {
    text-align: center;
    padding: 0 5em;
  }
  .infoSection {
    grid-template-columns: repeat(3, 1fr);
  }
  .timerSection {
    margin: 0 33vw;
  }
  .speakersSection {
    grid-template-columns: repeat(3, 1fr);
  }
  .videosList {
    grid-template-columns: repeat(3, 1fr);
    margin: 3em 5em 6em;
  }
  .faq_part .container-fluid {
    width: 70vw;
  }
}
@media only screen and (max-width: 768px) {
  .homeCover {
    height: 60vh;
  }
  .homeCover img {
    width: 80%;
  }
  .homeCover h1 {
    font-size: 1.5em;
  }
  .homeCover a {
    bottom: 3em;
    margin-top: 2em;
  }
  .homeCover a button {
    font-size: 1em;
  }
  .parallaxBg {
    height: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .parallaxBg .content {
    -webkit-align-self: center;
            align-self: center;
    padding: 5vh 15px;
  }
  .parallaxBg .content .heading {
    font-size: 1.3rem !important;
    text-align: center;
    line-height: 1.7rem;
  }
  .parallaxBg .authorImage {
    -webkit-align-self: center;
            align-self: center;
    padding: 0;
  }
  .parallaxBg .authorImage img {
    height: 30vh;
  }
  .parallaxBg::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .timerSection {
    grid-template-columns: repeat(1, 1fr);
  }
  .parallaxBg {
    min-height: 30vh;
  }
  .parallaxBg .content .heading {
    font-size: 1.7em;
  }
}
.faq_part {
  margin-bottom: 3em;
  width: 100%;
  color: #000;
}

.faqs {
  width: 100%;
}

.faqs .faq {
  margin: 15px 5px;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url(/static/media/arrow-down-mint.14e93985.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}
.faqs .faq .faq-answer a {
  color: #e62b1e;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}/*# sourceMappingURL=HomeComponent.css.map */
.mainSection {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 2em;
  margin: 3em 20px;
}

.mainSection > div .title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  border-left: 4px solid #e62b1e;
  padding-left: 20px;
}

.mainSection > div .title sup {
  color: #e62b1e;
  font-weight: bold;
}

.mainSection > div p {
  padding: 10px 10px 0 20px;
}

.tedxbitd {
  padding: 0px;
}

.tedxbitd p {
  padding: 10px 10px 0 40px;
}

.blackBg {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 30px 20px;
  margin: 5em 0;
}

.blackBg h2 {
  font-weight: bold;
  margin-bottom: 1em;
}

.blackBg p {
  font-size: 0.95em;
}

@media only screen and (min-width: 992px) {
  .mainSection {
    -ms-grid-columns: (1fr 2fr)[1];
        grid-template-columns: repeat(1, 1fr 2fr);
    margin: 3em 5em;
  }
  .mainSection .ted p {
    border-right: 1px solid #c5c5c5;
  }
  .mainSection .tedx .title {
    border-left: none;
    padding-left: 0;
  }
  .mainSection .tedx p {
    padding-left: 0;
  }
  .tedxbitd {
    padding: 30px;
  }
  .tedxbitd p {
    padding: 10px 10px 0 70px;
  }
  .blackBg {
    padding: 60px 15%;
  }
}
/*# sourceMappingURL=AboutUsComponent.css.map */
.coverCont {
  position: relative;
  background-image: url(/static/media/booking-background.66171d12.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 35vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding: 0 20px;
}

.coverCont::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.coverCont h1 {
  color: #fff;
  font-weight: bold;
  font-size: 2.5em;
  z-index: 1;
}

@media only screen and (min-width: 992px) {
  .coverCont {
    height: 50vh;
  }
  .coverCont h1 {
    font-size: 3.5em;
  }
}
/*# sourceMappingURL=PageCover.css.map */

.n-lineTitle{
    margin-top: 5rem;

}

.n-themeDiv{
    width: 100%;
    text-align: justify;
    /* background: rgb(108,0,0);
background: radial-gradient(circle, rgba(108,0,0,1) 0%, rgba(255,40,40,1) 57%, rgba(108,0,0,1) 100%); */
    background: rgb(108,0,0);
    background: linear-gradient(90deg, rgba(108,0,0,1) 0%, rgba(255,40,40,1) 57%, rgba(108,0,0,1) 100%);
background: rgb(191,0,0);
background: linear-gradient(90deg, rgba(191,0,0,1) 0%, rgba(255,40,40,1) 57%, rgba(191,0,0,1) 100%);
    margin: 3rem auto;
    padding: 5rem;
    
    /* border-radius: 9999px; */
    transition: 0.3s all ease-in-out;
}



.n-title{
    text-align: center;
    width: 50%;
    font-size: 2rem;
    font-family: 'Dancing Script', cursive;
   position: relative;
    background: white;
    color: rgb(207, 30, 30);
    /* color: linear-gradient(90deg, rgba(191,0,0,1) 0%, rgb(255, 114, 114) 57%, rgba(191,0,0,1) 100%); */
    margin: 0 auto;
    padding: 10px;
}

.n-themeDiv img{
    position: absolute;
    top: 18%;
    -webkit-filter: invert();
            filter: invert();
    opacity: 0.2;
    width: 40%;
    height: 90%;
    left: 33%;
}
.n-themeDiv  > p{
    
    font-family: 'Lugrasimo', cursive;
    font-family: 'Pangolin', cursive;
    font-size: 1.2rem;
}
.n-themeDiv p::selection {
    background: white;
    color: red;
}
/* .n-themeDiv:hover{
    cursor: pointer;
    transform: scale(1.03);
} */


@media only screen and (max-width: 992px) {
    .n-themeDiv{
        padding: 10px;
    }

    .n-title{

        width:100%
    }
    .n-themeDiv  > p{
        font-size: 1rem;
    }
    .n-themeDiv img{
        /* width: 100% ; */
        height: 17rem;
        object-fit:cover;
    }

        .tedxbitd p {
            padding: 10px 10px 0 4px;
        }

}
.speakersList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 5em;
  justify-items: center;
  margin: 3em 3em;
}

.speakersList .singleSpeaker {
  position: relative;
  border-radius: 7px;
  box-shadow: 15px 15px 31px #d9d9d9, -15px -15px 31px #ffffff;
  width: 300px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s all ease;
}

.speakersList .singleSpeaker img {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 350px;
}

.speakersList .singleSpeaker .content {
  padding: 30px 10px;
  text-align: center;
}

.speakersList .singleSpeaker .content .name {
  font-size: 1.4em;
  font-weight: 600;
}

.speakersList .singleSpeaker .content .post {
  font-size: 0.95rem;
  color: #5e5c5c;
  margin-top: 1em;
}

.speakersList .singleSpeaker .speakerHover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: rgba(230, 43, 30, 0.7);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  transition: 0.3s all ease;
}

.speakersList .singleSpeaker .speakerHover div {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  border: 5px solid #fff;
}

.speakersList .singleSpeaker .speakerHover h4 {
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0.5em;
}

.speakersList .singleSpeaker:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.speakersList .singleSpeaker:hover .speakerHover {
  top: 0;
}

@media only screen and (min-width: 768px) {
  .speakersList {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    margin: 3em 5em;
  }
}

@media only screen and (min-width: 992px) {
  .speakersList {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    margin: 3em 5em;
  }
}
/*# sourceMappingURL=SpeakersComponent.css.map */
/* No CSS *//*# sourceMappingURL=SingleSpeakerComponent.css.map */
.modalCont {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  opacity: 0;
  z-index: 10000;
  -webkit-animation: appear 0.3s linear;
          animation: appear 0.3s linear;
  transition: 0.3s all ease;
}

.modalCont .speakerModalContent .speakerModalBody {
  height: 80vh;
  width: 60vw;
  margin: 10vh auto 0;
  padding: 2em;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  overflow-y: scroll;
}

.modalCont .speakerModalContent .speakerModalBody::-webkit-scrollbar {
  width: 3px;
}

.modalCont .speakerModalContent .speakerModalBody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.modalCont .speakerModalContent .speakerModalBody::-webkit-scrollbar-thumb {
  background-color: #000;
}

.modalCont .speakerModalContent .speakerModalBody .header {
  display: -webkit-flex;
  display: flex;
  text-align: left;
}

.modalCont .speakerModalContent .speakerModalBody .header img {
  height: 200px;
  border-radius: 7px;
}

.modalCont .speakerModalContent .speakerModalBody .header .details {
  margin-left: 2em;
}

.modalCont .speakerModalContent .speakerModalBody .header .details .name {
  font-weight: 600;
}

.modalCont .speakerModalContent .speakerModalBody p {
  word-wrap: break-word;
  text-align: left;
  font-size: 0.9rem;
  margin: 2em 0;
}

.modalCont .speakerModalContent .speakerModalBody div button {
  padding: 10px 45px;
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.modalCont.show {
  opacity: 1;
  pointer-events: all;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .modalCont .speakerModalContent .speakerModalBody {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 90vh;
    width: 90vw;
    padding: 2em 1em;
    margin-top: 5vh;
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .modalCont .speakerModalContent .speakerModalBody::after {
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .modalCont .speakerModalContent .speakerModalBody .header {
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    z-index: 1;
  }
  .modalCont .speakerModalContent .speakerModalBody .header img {
    display: none;
  }
  .modalCont .speakerModalContent .speakerModalBody .header .details {
    margin-left: 0;
  }
  .modalCont .speakerModalContent .speakerModalBody p {
    position: relative;
    font-size: 0.85rem;
    z-index: 1;
  }
  .modalCont .speakerModalContent .speakerModalBody div {
    position: relative;
    z-index: 1;
  }
  .modalCont .speakerModalContent .speakerModalBody div button {
    background: transparent;
    border: 2px solid #e62b1e;
    background: transparent;
    transition: 0.3s all ease;
  }
  .modalCont .speakerModalContent .speakerModalBody div button:hover {
    background-color: #e62b1e;
    color: #fff;
  }
}

@media only screen and (min-width: 768px) {
  .modalCont .speakerModalContent .speakerModalBody {
    background-image: none !important;
  }
}
/*# sourceMappingURL=SpeakerModal.css.map */
.comingSoonContainer {
  margin: 0 20px 20px;
}

.comingSoonContainer .landing {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  -webkit-align-items: center;
          align-items: center;
}

.comingSoonContainer .landing .content {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
}

.comingSoonContainer .landing .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
}

.comingSoonContainer .landing .content .text {
  font-size: 0.95em;
  color: #616161;
  margin: 7px 0 0 0;
  text-align: justify;
}

.comingSoonContainer .landing .content .text a {
  display: inline-block;
  text-decoration: none;
  color: #e62b1e;
}

.comingSoonContainer .landing .content .text a .ctaBtn {
  display: block;
  margin-top: 1em;
  padding: 12px 30px;
  font-size: 1em;
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #f8c3c0;
  min-width: 150px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.comingSoonContainer .landing .content .text a:hover .ctaBtn {
  background-color: #fff;
  border-color: #e62b1e;
}

.comingSoonContainer .landing img {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  width: 100%;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #000;
  text-align: center;
  height: 1.5em;
}

.hr-text::before {
  content: "";
  background: linear-gradient(to right, transparent, #000, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text::after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  background-color: #fcfcfa;
}

@media only screen and (min-width: 992px) {
  .comingSoonContainer .landing {
    margin: 0 5vw;
    height: 90vh;
    -ms-grid-columns: (45% 55%)[1];
        grid-template-columns: repeat(1, 45% 55%);
  }
  .comingSoonContainer .landing .content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
  .comingSoonContainer .landing img {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}
/*# sourceMappingURL=ComingSoonComponent.css.map */
.modalCont {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  opacity: 0;
  z-index: 10000;
  -webkit-animation: appear 0.3s linear;
          animation: appear 0.3s linear;
  transition: 0.3s all ease;
}

.modalCont .modalContent .modalBody {
  width: 60vw;
  margin: 20vh auto 0;
  padding: 2em;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
}

.modalCont .modalContent .modalBody img {
  height: 100px;
  margin-bottom: 3em;
}

.modalCont .modalContent .modalBody p {
  word-wrap: break-word;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 2em;
}

.modalCont .modalContent .modalBody div button {
  padding: 10px 45px;
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.modalCont.show {
  opacity: 1;
  pointer-events: all;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .modalCont .modalContent .modalBody {
    width: 90vw;
    padding: 2em 1em;
  }
  .modalCont .modalContent .modalBody img {
    height: 80px;
    margin-bottom: 2em;
  }
  .modalCont .modalContent .modalBody p {
    font-size: 1.2em;
  }
}
/*# sourceMappingURL=AlertModalComponent.css.map */
.speakersList2023{
    margin-top: 3rem;
    /* height: 90vh; */
    width: 100%;
    z-index: 10;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    display: grid;
    grid-template-columns: auto auto auto;
}

.speakerPopupContainer{
    width: 100vw;
    
    display: none;
   
}

.speakerPopupContainer.open{
    display: block;

}

.speakersList2023.open{
    position: relative;


}

.speakerPageCover{
    position: absolute;
    height: 200vh;
    width: 100%;
    top: -1%;
    background: black;
    opacity: 0.7;
    z-index: 1000;

    
}

.speakerPopupContainer>*{
    margin: 0 auto;
}

@media screen and (max-width: 700px) {
    .speakersList2023 {
        width: 100vw;
        overflow-x: hidden;
        grid-template-columns: auto;
    }
    .speakerPopupContainer{
        width: 100vw;;
        overflow-x: hidden;
    }
    .speakerPageCover{
        position: absolute;
        height: inherit;
        width: 100vw;
        overflow-x: hidden;
        right: 0;
        bottom: -150rem;
    }
    
}
.speakerCard {
    width: 250px;
    height: 314px;
    background: rgb(103, 225, 255);
    background: white;
    transition: all 0.4s;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 8rem;
  }
  .speakerCard img{
    width: 250px;
    height: 314px;
    object-fit: cover;
    border-radius: 7px;
    
  }
  
  .first-content{
  position:relative;
  }
  
  .first-content > div{
    position : absolute;
    bottom:-25%;
    font-size: 1.5rem;
    text-align:center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  
  .first-content > div > span:nth-child(1){
    color:black;
    font-weight: bold;
    font-size: 1.1rem;
}

.first-content > div > span:nth-child(2){
    color: red;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 20px;
}
  
  .speakerCard:hover {
    border-radius: 15px;
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
    background: rgb(103, 151, 255);
    background: white;
  }
  
  .first-content {
    height: 100%;
    width: 100%;
    transition: all 0.4s;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    opacity: 1;
    border-radius: 15px;
  }
  
  .speakerCard:hover .first-content {
    height: 0px;
    opacity: 0;
  }
  
  .second-content {
    height: 0;
    width: 100%;
    
    opacity: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-radius: 15px;
    transition: all 0.4s;
    font-size: 0px;
    -webkit-transform: rotate(90deg) scale(-1);
            transform: rotate(90deg) scale(-1);
  }
  
  .second-content span:nth-child(1){
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    width: 100%;
    color: red;
  }
  
  .second-content span:nth-child(2){
    padding: 10px;
    background: reds;
    background: black;
    color: white;
    
    margin-top: 1.5rem;
    font-size: 1rem;
  }
  
  .speakerCard:hover .second-content {
    opacity: 1;
    height: 100%;
    font-size: 1.8rem;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
.speakerPopup{
    width: 90%;
    height: inherit;
    border: 2px solid red;
    background: white;
    position: fixed;
    top: 15vh;
    left: 5%;
    z-index: 5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 15px;
    padding: 1rem;
    z-index: 10000;
    /* position: relative; */
}

.speakerPopup > p{
    position: absolute;
    color: red;
    top: 2%;
    right: 2%;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;

}

.speakerPopup img{
    -webkit-flex: 1 1;
            flex: 1 1;
    border-radius: 7px;
    width: 18rem;
}

.speakerPopupDetails{
    -webkit-flex: 2 1;
            flex: 2 1;
    height: 60vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 5rem;
    padding-right: 5rem;
}
.speakerPopupDetails div{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 2rem;
}

.speakerPopupDetails div span:nth-child(2){
    color: red;
    font-weight: bold;
    font-size: 1.2rem;


}
.speakerPopupDetails span:nth-child(1){
    font-size: 2.5rem;
    font-weight: bold;
}

.speakerPopupDetails > span{
    text-align: justify;
}

@media screen and (max-width: 700px) {
    .speakerPopup{
        width: 80vw;
        overflow-x: hidden;
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 80vh;
        top: 10vh;
        left: 10vw;
    }
    .speakerPopup img{
        width: 100vw;
            overflow-x: hidden;
        margin-top: 9em;
        width: 15rem;
        display: none;
    }
    .speakerPopupDetails div span:nth-child(1){
        font-size: 1.5rem;

    }
    
.speakerPopupDetails{
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 1.5rem;
}
    
}
.mt-5 {
  margin-top: 5em;
}

.teamCont {
  margin: 3em 20px;
}
.teamCont .lineTitle span {
  cursor: pointer;
}
.teamCont .lineTitle span.opened::after {
  content: "\f106";
  font-family: "FontAwesome";
  margin-left: 0.5em;
}
.teamCont .lineTitle span.closed::after {
  content: "\f107";
  font-family: "FontAwesome";
  margin-left: 0.5em;
}
.teamCont .lineTitle::after {
  position: unset;
}
.teamCont .lineTitle::after ::shadow {
  font-weight: bold;
}
.teamCont .teamList {
  margin-top: 3em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  -webkit-justify-content: center;
          justify-content: center;
  place-items: center;
  grid-gap: 2em;
  padding: 0 15px;
}

@media only screen and (min-width: 768px) {
  .teamCont .teamList {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10vw;
  }
}
@media only screen and (min-width: 1200px) {
  .teamCont .teamList {
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=TeamComponent.css.map */
.memberCard .memberImage {
  position: relative;
  background-color: #ffe9e9;
  padding: 20px 0 0;
  text-align: center;
  border-radius: 10px;
}
.memberCard .memberImage img {
  height: 350px;
}
.memberCard .memberImage .memberQuote {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  padding: 0 10px;
  text-align: center;
  color: #fff;
  font-style: italic;
  font-size: 0.8rem;
  height: 70px;
  display: grid;
  place-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.memberCard .memberImage .memberSocial {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}
.memberCard .memberImage .memberSocial .singleSocial {
  display: inline;
  position: relative;
  color: #f33066;
  font-size: 1.2rem;
  cursor: pointer;
}
.memberCard .memberImage .memberSocial .singleSocial:not(:first-child) {
  margin-left: 10px;
}
.memberCard .memberImage .memberSocial .singleSocial:hover .socialTooltip {
  opacity: 1;
  pointer-events: all;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip {
  position: absolute;
  min-width: 250px;
  bottom: 0;
  right: 0;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transition: 0.3s all ease;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 7px;
  padding: 20px 10px;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .image {
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .image img {
  height: 45px;
  width: auto;
  border-radius: 50%;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .text {
  margin-left: 1em;
  white-space: nowrap;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .text h4 {
  font-size: 0.8rem;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .text h5 {
  font-size: 0.65rem;
  font-weight: 400;
  color: #e9e7e7;
  margin-bottom: 1em;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .text .cta {
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid #fff;
  font-size: 0.9rem;
  padding: 5px 15px;
  margin: 0;
  font-size: 0.65rem;
  font-weight: 600;
  transition: 0.3s all ease;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .details .text .cta:hover {
  color: #000;
  background-color: #fff;
}
.memberCard .memberImage .memberSocial .singleSocial .socialTooltip .whiteSpace {
  height: 30px;
}
.memberCard .memberDetails {
  padding: 25px 15px;
}
.memberCard .memberDetails .name {
  font-size: 1.1rem;
  font-weight: 600;
}
.memberCard .memberDetails .post {
  font-size: 0.9rem;
  font-weight: 500;
  color: #797979;
}/*# sourceMappingURL=ImageComponent.css.map */

.person-card {
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: column;

          flex-direction: column;
  position: relative;
  border-radius: 2.5em 0;
  margin: 3em 0;
  width: 300px;
  background: #ebecf0;
  transition: 0.5s all ease;
}

/* .card-1{
    background: 	#D3D3D3; 
  }
  
  .card-2{
    background: #9ACD32;
  }
  
  .card-3{
     background: #48D1CC;
  }
  
  .card-4{
    background: #7B68EE;
  }
  
  .card-5{
    
    background: #FF8C00;
  }
  
  .card-6{
   
     background: yellow;
  }
   */

.person-card:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  -webkit-filter: none;
          filter: none;
  cursor: pointer;
  /*   position: absolute;
        height: 100%;
        width: 100%;
        top: 100%;
        left: 0;
        background-color: rgba(230, 43, 30, 0.7);
        background-color: rgba(43, 30, 230, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: 0.3s all ease; */
}
.card-img {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-image: url(/static/media/card-bg.60a69204.png);
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-top-left-radius: 2.5rem !important;
  border-bottom-right-radius: 2.5rem !important;
}
.person-name,
.position {
  margin-left: 0.5em;
}

.position {
  line-height: 20px;
}

.person-card img {
  position: relative;
  padding: 0.5em 10px 0;
  height: 300px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.card-desc {
  font-weight: bold;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.8;
  width: 100%;
  color: white;
  /* text-align: center; */
  padding-top: 10px;
  line-height: 0.7em;

  /*   padding-left:10px  */
  border-bottom-right-radius: 2.5em;
}
/* hover-text */
/* .person-card:hover{
   opacity: 0.5; position:absolute;
    top: 150%;
    top: 100%;
  /*   width: 100%;
    height: 100%; */
/*   background:red;
  transition: all 1s ease-in-out; } */

.socialsDiv {
  display: none;
  /* align-items: center;
    justify-content: center; */
  position: absolute;
  bottom: -2.5rem;
  right: 4rem;
  margin: auto;
  transition: 0.5s all ease-in-out;
}

.socialsDiv .socials {
  height: 45px;
  /* color: red; */
  /* background: none; */
  /* display: none; */
}

.cardContainer:hover .socialsDiv {
  display: block;
}

.socialsDiv:hover {
  display: -webkit-flex;
  display: flex;
} 

@media screen and (max-width: 670px) {
  .CardContainer {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: auto;
    /* flex-direction: column; */
  }
  .person-card {
    width: 300px;
  }
  .socialsDiv {
    bottom:  -3em;
  }
}

@media screen and (max-width: 780px) {
  .CardContainer {
    grid-template-columns: repeat(2, 1fr);
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .person-card {
    width: 300px;
  }
  .socialsDiv {
    bottom: -3em;
  }
}

@media all and (min-width: 745px) {
    
      .teamList {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        margin-bottom: 1rem;
        padding-bottom: 0.25rem;
      }

  }

.lineTitle img{
    height: 70px;
    /* width: 70px; */
    display: inline;
    margin:-1.5rem 0;
    /* overflow: hidden; */
    -webkit-filter: drop-shadow(0 0 0 8px black);
            filter: drop-shadow(0 0 0 8px black);
    

}



/* 
.teamList{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: 100px;
    justify-content: space-evenly;
    align-items: center;
}
  

.card-l-cen{
    grid-area:b;
}

.card-r-cen{
    grid-area: c;
} */
.merchContent {
    height: 100%;
    width: 100%;
    /* overflow-x: hidden; */
    margin: auto;
}
.mainContent {
    display: -webkit-flex;
    display: flex;
    /* gap: 6em; */
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
    background: black;
    color: white;
    /* font-family:; */
    width: 100%;
}

.mainContent-top{
    padding: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.mainContent-top >  img {
    width: 30%;
}

.mainContent-top p {
    font-size: 2.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.sizeBtn {
    /* margin-top: 2em; */
    margin-bottom: 2rem;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.sizeBtn button {
    padding: 10px 30px;
    background-color: #e62b1e;
    color: #fff;
    border-radius: 7px;
    border: 2px solid #e62b1e;

    text-align: center;
    text-decoration: none;
    transition: 0.3s all ease;
}

.shirtBox{
    margin-bottom: 2rem;
    box-shadow: 2px 2px 10px black;
}

.sizeBtn button:hover {
    background: transparent;
    color: #e62b1e;
}

.shirtSizeBox {
    background: black;
    left: 30%;
    right: 30%;
    top: 25%;
    height: 50vh;
    border: 2px solid #e62b1e;
    position: fixed;
    z-index: 99;
    display: -webkit-flex;
    display: flex;
    border-radius: 10px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.shirtSizeBox img {
    width: 70%;
}
.hideSizeBox {
    display: none;
}
.shirtDesc {
    padding-top: 2rem;
    padding-bottom: 4rem;
    height: inherit;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -webkit-align-items: center;
            align-items: center;
    /* justify-content: space-evenly; */
    -webkit-flex-direction: column;
            flex-direction: column;
    
background: linear-gradient(90deg, rgba(119,1,1,1) 0%, rgba(168,0,0,1) 50%, rgba(119,1,1,1) 100%);
}
.shirtDesc .shirtBox {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 350px;
    height: 350px;
    /* height: 380px; */
    border:2px solid red;
    border-radius: 0.5em;
}

.shirtDesc span{
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.shirtBoxContainer{
    width: 100%;
    display: grid;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    grid-template-columns: auto auto auto;

}

.shirtBox{
    background: white;
}

.shirtDesc .shirtBox img { 
 width: 100%;
}
.buyBtn {
    /* height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; */
    padding: 10px;
    background: black !important;
    border: none !important;
}
.sizeBtn{
    display: -webkit-flex;
    display: flex;
    /* align-items: center; */
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;

}

.sizeBtn span{
    font-size: 1.2rem;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    /* flex-direction: column; */

}

.sizeBtn span p:nth-child(1){
    font-size: 0.8rem;
    margin-right: 5px;
}

.boxDesc{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.boxDesc p:nth-child(1){
    /* font-size: 1.5rem; */
    background: black;
    background: none;
    background: linear-gradient(90deg, rgba(119,1,1,1) 0%, rgba(168,0,0,1) 50%, rgba(119,1,1,1) 100%);

    color: red;
    color: #e62b1e;
    color: white;
    width: 80%;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    text-align: center;
    font-weight: bold;
    padding: 0.5rem;
}

.boxDesc p:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: -0.5rem;
}

.boxDesc a button{
    background: black;
    color: white;
    /* font-weight: bold; */
    padding: .5rem;
    margin-top:-.5rem ;
    border: 2px solid red;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    -webkit-animation-name: animateButton;
            animation-name: animateButton;
    -webkit-animation: animateButton 2s infinite;
            animation: animateButton 2s infinite;
    
}

@-webkit-keyframes animateButton{
    0%{
        /* background: red; */
        color: white;
    }
    
    25%{
        /* background: red;
        background: black; */
        color: red;
    }
    50%{
        /* background: black;
        background: red; */
        color: white;
    }
    75%{
        /* background: black;
        background: red; */
        color: red;
    }
    100%{
        /* background: black;
        background: red; */
        color: red;
        color: white;
    }


}

@keyframes animateButton{
    0%{
        /* background: red; */
        color: white;
    }
    
    25%{
        /* background: red;
        background: black; */
        color: red;
    }
    50%{
        /* background: black;
        background: red; */
        color: white;
    }
    75%{
        /* background: black;
        background: red; */
        color: red;
    }
    100%{
        /* background: black;
        background: red; */
        color: red;
        color: white;
    }


}

.boxDesc a button:hover{
    color: white;
    -webkit-animation: none;
            animation: none;
}

@media screen and (max-width: 700px) {
    .shirtBoxContainer{
        grid-template-columns: auto;
    }
    
    .shirtDesc{
        width: 100vw;
        overflow-x: hidden;
    }
        .mainContent-top{
            -webkit-flex-direction: column;
                    flex-direction: column;
            width: 100vw;
            overflow-x: hidden;
        }
                .mainContent-top>img{
                    width: 80%;
                    -webkit-flex: 1 1;
                            flex: 1 1;
                }
                .mainContent-top p{
                    -webkit-flex: 2 1;
                            flex: 2 1;
                    margin-top: 2rem;
                    font-size: 1.5rem;
                    text-align: center;
                }

                .shirtDesc span{
                    text-align: center;
                }
}

.sponsorsCont {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 3em;
  margin: 3em 20px;
}
.sponsorsCont .sponsorsList {
  margin: 3em 0 1em;
}

.applyBtn {
  padding: 30px 30px;
}
.applyBtn h3 {
  cursor: default;
}
.applyBtn .inputGroup a {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .sponsorsCont {
    grid-template-columns: repeat(2, 1fr);
  }
  .applyBtn .inputGroup a {
    width: 30%;
  }
}
@media only screen and (min-width: 992px) {
  .sponsorsCont {
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=SponsorsComponent.css.map */
.personCard {
  position: relative;
  width: 300px;
  box-shadow: 12px 12px 29px #d6d6d6, -12px -12px 29px #ffffff;
  border-radius: 10px;
  margin: 0 auto 1.5em;
  transition: 0.3s all ease;
}

.personCard .personImage {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.personCard:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
/*# sourceMappingURL=ImageComponent.css.map */
.sponsor-title{
    width: 100%;
        position: relative;
        
}


.sponsor-title:before {
    content: "";
    display: block;
    width: 30%;
    height: 2px;
    background: #e62b1e;
    left: 10%;
    top: 50%;
    position: absolute;
  }
.sponsor-title:after {
    content: "";
    display: block;
    width: 30%;
    height: 2px;
    background: #e62b1e;
    right: 10%;
    top: 50%;
    position: absolute;
  }

  #AssociateSponsor{
    max-width: 100%;
        position: relative;
  }

  #AssociateSponsor:before {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #e62b1e;
    left: 10%;
    top: 50%;
    position: absolute;
  }
  #AssociateSponsor:after {
    content: "";
    display: block;
    width: 25%;
    height: 2px;
    background: #e62b1e;
    right: 10%;
    top: 50%;
    position: absolute;
  }

  #CoSponsor:before{
    width: 30% ;

  }
#CoSponsor:after{
  width: 30% ;
}

.hoverEffect{
  position: relative !important;
  transition: 0.3s all ease-in-out;
  /* z-index: 1000; */
  
}

.hover{
  position: absolute;
  top: 0;
  left: 18%;
  width: 300px;
  height: 300px;
  opacity: 0.9;
  background: black;
  color: white;
  font-weight: bold;

  
  display: -webkit-flex;

  
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  
  
  padding: 20px;
  border-radius: 8px;

  box-sizing: border-box;
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.hover p{
  text-align: center;
}

.hoverEffect:hover .hover{
  /* transform: scale(1.03); */
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
}

@media screen and (max-width:900px) {
  .sponsor-title:after{
    width: 22%;
  }
  .sponsor-title:before{
    /* display: none; */
    width: 22%;
}

#AssociateSponsor:before, #AssociateSponsor:after{
  width: 15% !important;
}

#CoSponsor:before,#CoSponsor:after{
  width: 24% !important;
}


  
}

  @media screen and (max-width:700px) {
    
    .responsive{
      width: 100vw !important;
      overflow-x: hidden;
    }
  
    .sponsor-title:after{
        display: none;
    }
    .sponsor-title:before{
        display: none;
    }

    #AssociateSponsor::before, #AssociateSponsor::after{
      display: none;
    }
   
    #CoSponsor:before{
      display: none;
    }
    #CoSponsor:after{
      display: none;
    }

    .hover{
      left: 12%;
    }

    
    
  }
.contactCont .cardsCont {
  position: relative;
}
.contactCont .cardsCont .cards {
  position: absolute;
  width: 80vw;
  top: -2em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 3em;
}
.contactCont .cardsCont .cards .singleCard {
  display: inline-block;
  position: relative;
  background-color: #fff;
  box-shadow: 6px 6px 12px #d9d9d9;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  width: calc((80vw - 3em) / 2);
}
.contactCont .cardsCont .cards .singleCard .cardIcon {
  position: absolute;
  background-color: #fff;
  padding: 15px;
  border-radius: 50%;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 5px 5px 10px #d9d9d9;
}
.contactCont .cardsCont .cards .singleCard .cardIcon img {
  height: 70px;
}
.contactCont .cardsCont .cards .singleCard .cardHeading {
  margin: 3em 30px 0.5em;
  font-size: 1.5em;
  font-weight: 600;
}
.contactCont .cardsCont .cards .singleCard .cardContent {
  font-size: 0.9em;
  margin-bottom: 1.5em;
  padding: 0 20px;
}
.contactCont .cardsCont .cards .singleCard .cardCta {
  background-color: #f8c3c0;
  color: #e62b1e;
  padding: 12px 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 600;
}
.contactCont .contactForm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 18em 0 5em;
}
.contactCont .contactForm .leftSide {
  margin-right: 5em;
}
.contactCont .contactForm .leftSide img {
  height: 400px;
}
.contactCont .contactForm form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 0.9em;
}
.contactCont .contactForm form .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 0.2em;
}
.contactCont .contactForm form .inputGroup input,
.contactCont .contactForm form .inputGroup textarea {
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 500;
  height: 3.5em;
  padding: 20px;
  width: 90%;
  border-radius: 25px;
  border: none;
  outline: none;
}
.contactCont .contactForm form .inputGroup input::-webkit-input-placeholder, .contactCont .contactForm form .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}
.contactCont .contactForm form .inputGroup input:-ms-input-placeholder, .contactCont .contactForm form .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}
.contactCont .contactForm form .inputGroup input::placeholder,
.contactCont .contactForm form .inputGroup textarea::placeholder {
  color: #e62b1e;
  font-weight: 500;
}
.contactCont .contactForm form .inputGroup textarea {
  height: 200px;
}
.contactCont .contactForm form .inputGroup button {
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  width: 90%;
  padding: 15px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}
.contactCont .contactForm form .inputGroup:not(:first-child) {
  margin-top: 1em;
}
.contactCont .contactForm form .socialMedia {
  margin-top: 2em;
  width: 90%;
  text-align: center;
}
.contactCont .contactForm form .socialMedia a {
  transition: 0.3s all ease;
}
.contactCont .contactForm form .socialMedia a i {
  padding: 20px;
  border-radius: 50%;
  font-size: 1.4em;
  box-shadow: 6px 6px 13px #d9d9d9, -6px -6px 13px #ffffff;
  transition: 0.3s all ease;
}
.contactCont .contactForm form .socialMedia a i.fa-facebook {
  color: #4267b2;
}
.contactCont .contactForm form .socialMedia a i.fa-facebook:hover {
  background-color: #4267b2;
  color: #fff;
}
.contactCont .contactForm form .socialMedia a i.fa-instagram {
  color: #f14164;
}
.contactCont .contactForm form .socialMedia a i.fa-instagram:hover {
  background-color: #f14164;
  color: #fff;
}
.contactCont .contactForm form .socialMedia a i.fa-linkedin {
  color: #0077b5;
}
.contactCont .contactForm form .socialMedia a i.fa-linkedin:hover {
  background-color: #0077b5;
  color: #fff;
}
.contactCont .contactForm form .socialMedia a i.fa-twitter {
  color: #00acee;
}
.contactCont .contactForm form .socialMedia a i.fa-twitter:hover {
  background-color: #00acee;
  color: #fff;
}
.contactCont .contactForm form .socialMedia a:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.contactCont .contactForm form .socialMedia a:not(:first-child) {
  margin-left: 1em;
}

@media only screen and (max-width: 992px) {
  .contactCont .cardsCont .cards {
    width: 90vw;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
    grid-row-gap: 5em;
    top: -1em;
  }
  .contactCont .cardsCont .cards .singleCard {
    width: 100%;
  }
  .contactCont .cardsCont .cards .singleCard .cardContent {
    margin-bottom: 2.5em;
  }
  .contactCont .contactForm {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 40em 0 5em;
  }
  .contactCont .contactForm .leftSide {
    margin: auto;
  }
  .contactCont .contactForm .leftSide img {
    height: 400px;
  }
  .contactCont .contactForm form .heading {
    text-align: center;
    padding: 0.5em;
  }
  .contactCont .contactForm form .inputGroup input,
  .contactCont .contactForm form .inputGroup textarea,
  .contactCont .contactForm form .inputGroup button {
    margin: 0 5%;
  }
  .contactCont .contactForm form .socialMedia {
    margin: 2em 5% 0;
  }
}
@media only screen and (max-width: 600px) {
  .contactCont .cardsCont .cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .contactCont .contactForm {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 43em 0 5em;
  }
  .contactCont .contactForm .leftSide {
    margin: auto;
  }
  .contactCont .contactForm .leftSide img {
    width: 100%;
    height: auto;
  }
  .contactCont .contactForm form .heading {
    padding: 0.5em;
  }
  .contactCont .contactForm form .inputGroup input,
  .contactCont .contactForm form .inputGroup textarea,
  .contactCont .contactForm form .inputGroup button {
    margin: 0 5%;
  }
  .contactCont .contactForm form .socialMedia {
    margin: 2em 5% 0;
  }
}/*# sourceMappingURL=ContactUsComponent.css.map */
.scheduleCont {
  position: relative;
  padding: 0 15vw 10vh;
  background-image: url(/static/media/booking-background.66171d12.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}

.scheduleCont::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.scheduleCont .content {
  position: relative;
  z-index: 1;
}

.scheduleCont .content table {
  margin: auto;
}

.scheduleCont .content table tbody td {
  padding: 15px 0;
  font-size: 1.3rem;
  font-weight: 600;
  width: 250px;
  text-align: center;
}

.scheduleCont .content table tbody td.ta-l {
  text-align: left;
}

.scheduleCont .content div {
  margin-top: 10vh;
  text-align: center;
}

.scheduleCont .content div a {
  display: inline-block;
  text-decoration: none;
  color: #e62b1e;
}

.scheduleCont .content div a button {
  display: block;
  padding: 12px 30px;
  font-size: 1em;
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #e62b1e;
  min-width: 150px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.scheduleCont .content div a:hover button {
  background: transparent;
  color: #e62b1e;
}

@media only screen and (max-width: 768px) {
  .scheduleCont {
    position: relative;
    padding: 0 20px 10vh;
  }
  .scheduleCont .content table tbody td {
    padding: 15px 0;
    font-size: 0.95rem;
    width: 200px;
  }
  .scheduleCont .content div {
    margin-top: 5vh;
  }
  .scheduleCont .content div a button {
    padding: 12px 30px;
    font-size: 1em;
    min-width: 150px;
  }
}
/*# sourceMappingURL=ScheduleComponent.css.map */
.bookingCont {
  background-color: #111318;
  padding: 1rem;
  min-height: 100vh;
}
.bookingCont .header {
  position: relative;
  background-image: url(/static/media/homeCover.a6de1f23.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  border-radius: 20px;
  box-shadow: 15px 15px 20px #0b0d10, -15px -15px 20px #171920;
}
.bookingCont .header.height-100 {
  height: calc(100vh - 2rem) !important;
}
.bookingCont .header::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
}
.bookingCont .header .content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  height: 100%;
  width: 60%;
  padding: 0 4rem;
  color: #ebf5fc;
  z-index: 1;
}
.bookingCont .header .content .title {
  font-family: "Poppins", sans-serif;
  font-size: 4rem;
  font-weight: 700;
}
.bookingCont .header .content .details {
  margin: 2rem 0;
}
.bookingCont .header .content .details .single-detail {
  display: grid;
  grid-template-columns: 30px auto;
  -webkit-align-items: center;
          align-items: center;
}
.bookingCont .header .content .details .single-detail:not(:first-child) {
  margin-top: 1rem;
}
.bookingCont .header .content a {
  display: inline-block;
  padding: 10px 30px;
  background-color: #e62b1e;
  color: #fff;
  border-radius: 7px;
  border: 2px solid #e62b1e;
  text-align: center;
  text-decoration: none;
  margin-top: 1.5rem;
  transition: 0.3s all ease;
}
.bookingCont .header .content a:hover {
  background: transparent;
}
.bookingCont .header.referralHeader .content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 10vh 4rem;
  text-align: center;
}
.bookingCont .header.referralHeader .content input,
.bookingCont .header.referralHeader .content button {
  display: inline-block;
  padding: 10px 30px;
  border-radius: 7px;
  margin-top: 1.5rem;
  transition: 0.3s all ease;
  outline: none;
}
.bookingCont .header.referralHeader .content button {
  background-color: #e62b1e;
  color: #fff;
  border: 2px solid #e62b1e;
  text-align: center;
}
.bookingCont .header.referralHeader .content button:hover {
  background: transparent;
}
.bookingCont .header.referralHeader .content .PaymentButton {
  height: auto;
}
.bookingCont .body {
  margin: 4rem 0;
  padding: 0 5vw;
}
.bookingCont .body .referral-slab {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #1d2129;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 15px 15px 20px #0b0d10, -15px -15px 20px #171920;
}
.bookingCont .body .referral-slab .slab-title {
  color: #ebf5fc;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
.bookingCont .body .referral-slab a {
  display: inline-block;
  padding: 10px 30px;
  background-color: #e62b1e;
  color: #fff;
  border-radius: 7px;
  border: 2px solid #e62b1e;
  width: 100%;
  text-align: center;
  text-decoration: none;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  transition: 0.3s all ease;
}
.bookingCont .body .referral-slab a:hover {
  background: transparent;
}
.bookingCont .body .title {
  color: #ebf5fc;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 4rem;
}
.bookingCont .body .slabs-list {
  margin-top: 4em;
  display: -webkit-flex;
  display: flex;
  grid-gap: 3em;
  gap: 3em;
  height: 50vh;
}
.bookingCont .body .slabs-list .single-slab {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #1d2129;
  color: #707c80;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 15px 15px 20px #0b0d10, -15px -15px 20px #171920;
}
.bookingCont .body .slabs-list .single-slab .slab-header {
  text-align: center;
  color: #ebf5fc;
}
.bookingCont .body .slabs-list .single-slab .slab-header .price {
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}
.bookingCont .body .slabs-list .single-slab .slab-header .price strike {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}
.bookingCont .body .slabs-list .single-slab .slab-header .price span {
  font-size: 2.5rem;
  font-weight: 700;
  margin-left: 0.3rem;
}
.bookingCont .body .slabs-list .single-slab .slab-body {
  padding-top: 40px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}
.bookingCont .body .slabs-list .single-slab .slab-body .details .single-detail {
  display: grid;
  grid-template-columns: 30px auto;
}
.bookingCont .body .slabs-list .single-slab .slab-body .details .single-detail .desc,
.bookingCont .body .slabs-list .single-slab .slab-body .details .single-detail .desc a {
  color: #707c80;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
}
.bookingCont .body .slabs-list .single-slab .slab-body .details .single-detail:not(:first-child) {
  margin-top: 1rem;
}
.bookingCont .body .slabs-list .single-slab .slab-footer {
  padding: 30px 0 0;
}
.bookingCont .body .slabs-list .single-slab .slab-footer a {
  display: inline-block;
  padding: 10px 30px;
  background-color: #e62b1e;
  color: #fff;
  border-radius: 7px;
  border: 2px solid #e62b1e;
  width: 100%;
  text-align: center;
  text-decoration: none;
  transition: 0.3s all ease;
}
.bookingCont .body .slabs-list .single-slab .slab-footer a:hover {
  background: transparent;
}

@media only screen and (max-width: 1000px) {
  .bookingCont .header {
    height: 60vh;
  }
  .bookingCont .header .content {
    width: 100%;
    padding: 0 1rem;
  }
  .bookingCont .header .content .title {
    font-size: 2rem;
  }
  .bookingCont .header .content .details .single-detail {
    font-size: 0.9rem;
  }
  .bookingCont .header .content a {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  .bookingCont .header.referralHeader .content {
    padding: 5vh 1rem;
  }
  .bookingCont .body {
    padding: 0;
  }
  .bookingCont .body .slabs-list .single-slab {
    border-radius: 10px;
    padding: 30px 20px;
  }
  .bookingCont .body .slabs-list .single-slab:first-child {
    grid-column: span 2;
  }
  .bookingCont .body .slabs-list .single-slab .slab-header .price {
    font-size: 1.2rem;
  }
  .bookingCont .body .slabs-list .single-slab .slab-header .price strike {
    font-size: 0.9rem;
  }
  .bookingCont .body .slabs-list .single-slab .slab-header .price span {
    font-size: 2.5rem;
  }
  .bookingCont .body .slabs-list .single-slab .slab-body .details .single-detail .desc,
  .bookingCont .body .slabs-list .single-slab .slab-body .details .single-detail .desc a {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 768px) {
  .bookingCont .body .slabs-list {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 150vh;
  }
  .bookingCont .body .slabs-list .booking-card {
    width: 100%;
  }
  .bookingCont .body .slabs-list .single-slab:first-child {
    grid-column: span 1;
  }
}/*# sourceMappingURL=BookingComponent.css.map */
.booking-card {
    width: 100%;
    overflow: hidden;
    box-shadow: none;
    position: relative;
  }
  
  .bookingCont .body .slabs-list .single-slab {
    box-shadow: none;
  }

 

  .content {
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    /* box-shadow: 0px 0px 10px 1px #000000ee; */
    border-radius: 5px;
  }
  
.back {
    background-color: #151515;
    position: absolute;
    width: 100%;
    height: 100%;
    /* backface-visibility: hidden; */
    /* -webkit-backface-visibility: hidden; */
    border-radius: 5px;
    overflow: hidden;
  }
  
  .back {
    /* width: 100%;
    height: 100%; */
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .back::before {
    position: absolute;
    content: '';
    display: block;
    width: 160px;
    height: 160%;
    background: #e62b1e;
    
    -webkit-animation: rotation_481 3700ms infinite linear;
    
            animation: rotation_481 3700ms infinite linear;
  }
  
  .back-content {
   
    width: 99%;
    height: 99%;
   
  }
  
  
  
  @-webkit-keyframes rotation_481 {
    0% {
      -webkit-transform: rotateZ(0deg);
              transform: rotateZ(0deg);
    }
  
    0% {
      -webkit-transform: rotateZ(360deg);
              transform: rotateZ(360deg);
    }
  }
  
  
  
  @keyframes rotation_481 {
    0% {
      -webkit-transform: rotateZ(0deg);
              transform: rotateZ(0deg);
    }
  
    0% {
      -webkit-transform: rotateZ(360deg);
              transform: rotateZ(360deg);
    }
  }
 
  
  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    50% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
  
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
 
  
  @keyframes floating {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  
    50% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
  
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }

  @media screen and (max-width: 700px) {
    .booking-card{
      position: relative;
      height: 100vh;
      width: 100vh;
    }

    

  }
.single-slab{
  width: 100%;
  height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;  
    position: relative;
  
} 
.slabs-list {
  margin-bottom: 1em;
}
  .popup{
    width: 100%;
    margin: 0 auto;
    display: none;
  }
  .popup.open{
    display: block;
  }
 
  
   .iButton{
    position: absolute;
    right: 4%;
    top: 3%;
    font-weight: bold;
    cursor: pointer;
}
.iButton:hover{
      color: white;

  }

.referralBox {
  padding: 1em 0;
  width: 100%;
  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color:#1d2129 ;
  color: white;
}
.referralButton {
  display: inline-block;
    padding: 10px 30px;
    background-color: #e62b1e;
    color: #000;
    border-radius: 7px;
    border: 2px solid #e62b1e;
    width: 20%;
    text-align: center;
    text-decoration: none;
    transition: 0.3s all ease;
}
.referralButton {
  color: white;
  outline: none;
  text-decoration: none !important;
}

.referralButton:hover {
  color: white;
  background-color: black;
}

  @media screen and (max-width: 700px) {
    .referralButton {
      width: 70%;
    }
    .back-content{
     
      overflow: visible;
    }
    
  }
.iButtonComp{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    width: 70%;
    height: 60vh;
    border: 2px solid white;
    top: 20%;
    left: 15%;
    z-index: 5;
    background: black;
    border-radius: 15px;
   
    /* margin: 0 auto; */
    

}

.showBox {
    display: block !important;
}
.hideBox {
    display: none;
}
.iButtonComp p {
    font-weight: 700;
    color: white;
    font-size: 2rem;
} 

.iButtonComp-Details{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
    position: relative;
}

.iButtonComp-Details img{
    width: 70%;
    -webkit-filter: grayscale(1);
            filter: grayscale(1)
}

.changeColor{
    color: white !important;
}
.iButtonComp-Details .ticket-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.iButtonComp-Details .ticket-details p {
    font-size: 1.5em;
    margin-top: 1em;
    color: gray;
}
.removeFilter {
    -webkit-filter: grayscale(0) !important;
            filter: grayscale(0) !important;
}

.displayCont {
    display:  contents !important;
}

.iButtonComp-Close{
    position: absolute;
    top: 0;
    right: 3%;
    font-size: 1.5rem;
    
    cursor: pointer;
    font-weight: bold;
    color: white ;
    z-index: 10;
    
}

@media screen and (max-width: 700px) {
    .iButtonComp{
        width: 90%;
        height: inherit;
        top: 20%;
        left: 5%;
    }
    .iButtonComp-Details{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .iButtonComp-Details p:nth-child(1){
        margin-top: 2rem;
    }
    .iButtonComp-Details .ticket-details{
        display: none;
    }
    
    
}
.guidelinesCont {
  margin: 3em 5vw;
}

.guidelinesCont .heading {
  text-align: center;
  font-size: 1.7em;
}

.guidelinesCont section {
  margin: 2em 0;
}

.guidelinesCont section .subheading {
  font-size: 1.3em;
}

.guidelinesCont section p ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.guidelinesCont section p ol li {
  font-size: 0.9em;
  line-height: 1.6em;
}

.guidelinesCont section p ol li a {
  color: #e62b1e;
}

.guidelinesCont section p ol li strong {
  font-weight: 600;
}

.guidelinesCont section p ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.guidelinesCont section p ol li:not(:first-child) {
  margin-top: 0.7em;
}

.guidelinesCont section a {
  text-decoration: none;
  color: #e62b1e;
}

.guidelinesCont section a button {
  margin-top: 1em;
  padding: 12px 30px;
  font-size: 1em;
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #f8c3c0;
  min-width: 150px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.guidelinesCont section a:hover button {
  background-color: #fff;
  border-color: #e62b1e;
}

@media only screen and (min-width: 992px) {
  .guidelinesCont {
    margin: 3em 10vw;
  }
  .guidelinesCont .heading {
    font-size: 2em;
  }
  .guidelinesCont section p ol {
    margin: 1.2em 2em 0;
  }
  .guidelinesCont section p ol li {
    font-size: 0.9em;
  }
  .guidelinesCont section p ol li:not(:first-child) {
    margin-top: 0.7em;
  }
}
/*# sourceMappingURL=OpenMicGuidelinesComponent.css.map */
.submitCont {
  margin: 5em 0;
}

.submitCont .instructions {
  padding: 0 0 0 20px;
  margin: 0 auto 3em;
}

.submitCont .instructions h3 {
  font-weight: 500;
  font-size: 1.5em;
}

.submitCont .instructions ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.submitCont .instructions ol li {
  font-size: 0.9em;
  line-height: 1.6em;
}

.submitCont .instructions ol li a {
  color: #e62b1e;
}

.submitCont .instructions ol li strong {
  font-weight: 600;
}

.submitCont .instructions ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.submitCont .instructions ol li:not(:first-child) {
  margin-top: 0.7em;
}

.submitCont form {
  margin: 3em auto 0;
  width: 90vw;
}

.submitCont form .formRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.submitCont form .formRow:not(:first-child) {
  margin-top: 1.5em;
}

.submitCont form .inputGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.submitCont form .inputGroup #title,
.submitCont form .inputGroup #upload {
  width: 100%;
}

.submitCont form .inputGroup label {
  font-weight: 600;
  margin-bottom: 0.4em;
}

.submitCont form .inputGroup input,
.submitCont form .inputGroup select,
.submitCont form .inputGroup textarea {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
}

.submitCont form .inputGroup select {
  padding: 9px;
}

.submitCont form .inputGroup button {
  background-color: #fff;
  color: #e62b1e;
  font-weight: 600;
  border: 2px solid #e62b1e;
  padding: 10px 45px;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  margin: 2em auto 0;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.submitCont form .inputGroup button:hover {
  background-color: #e62b1e;
  color: #fff;
}

.submitCont form .inputGroup.progressBar {
  width: 100%;
  margin: 2em auto 0;
}

@media only screen and (min-width: 992px) {
  .submitCont {
    margin: 5em;
  }
  .submitCont .instructions {
    padding: 0 0 0 50px;
    margin-bottom: 3em;
  }
  .submitCont .instructions h3 {
    font-weight: 500;
    font-size: 1.5em;
  }
  .submitCont .instructions ol {
    margin: 1.2em 2em 0;
  }
  .submitCont .instructions ol li {
    font-size: 0.9em;
  }
  .submitCont .instructions ol li:not(:first-child) {
    margin-top: 0.7em;
  }
  .submitCont form {
    width: 75vw;
  }
  .submitCont form .formRow {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .submitCont form .formRow.singleInput {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .submitCont form .inputGroup.progressBar {
    width: 50%;
  }
}
/*# sourceMappingURL=OpenMicApplyComponent.css.map */
.ambassadorCont .contactForm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5em 0;
}

.ambassadorCont .contactForm .leftSide {
  margin-right: 5em;
}

.ambassadorCont .contactForm .leftSide img {
  width: 600px;
}

.ambassadorCont .contactForm form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 0.9em;
}

.ambassadorCont .contactForm form .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 0.2em;
}

.ambassadorCont .contactForm form .inputGroup input,
.ambassadorCont .contactForm form .inputGroup textarea {
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 500;
  height: 3.5em;
  padding: 20px;
  width: 100%;
  border-radius: 25px;
  border: none;
  outline: none;
}

.ambassadorCont .contactForm form .inputGroup input::-webkit-input-placeholder,
.ambassadorCont .contactForm form .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.ambassadorCont .contactForm form .inputGroup input:-ms-input-placeholder,
.ambassadorCont .contactForm form .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.ambassadorCont .contactForm form .inputGroup input::-webkit-input-placeholder, .ambassadorCont .contactForm form .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.ambassadorCont .contactForm form .inputGroup input:-ms-input-placeholder, .ambassadorCont .contactForm form .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.ambassadorCont .contactForm form .inputGroup input::placeholder,
.ambassadorCont .contactForm form .inputGroup textarea::placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.ambassadorCont .contactForm form .inputGroup textarea {
  height: 200px;
}

.ambassadorCont .contactForm form .inputGroup button {
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.ambassadorCont .contactForm form .inputGroup:not(:first-child) {
  margin-top: 1em;
}

.ambassadorCont .contactForm form .socialMedia {
  margin-top: 2em;
  width: 100%;
  text-align: center;
}

.ambassadorCont .contactForm form .socialMedia a {
  transition: 0.3s all ease;
}

.ambassadorCont .contactForm form .socialMedia a i {
  padding: 20px;
  border-radius: 50%;
  font-size: 1.4em;
  box-shadow: 6px 6px 13px #d9d9d9, -6px -6px 13px #ffffff;
  transition: 0.3s all ease;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-facebook {
  color: #4267b2;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-facebook:hover {
  background-color: #4267b2;
  color: #fff;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-instagram {
  color: #f14164;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-instagram:hover {
  background-color: #f14164;
  color: #fff;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-linkedin {
  color: #0077b5;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-linkedin:hover {
  background-color: #0077b5;
  color: #fff;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-twitter {
  color: #00acee;
}

.ambassadorCont .contactForm form .socialMedia a i.fa-twitter:hover {
  background-color: #00acee;
  color: #fff;
}

.ambassadorCont .contactForm form .socialMedia a:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.ambassadorCont .contactForm form .socialMedia a:not(:first-child) {
  margin-left: 1em;
}

@media only screen and (max-width: 992px) {
  .ambassadorCont .contactForm {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .ambassadorCont .contactForm .leftSide {
    margin: auto;
  }
  .ambassadorCont .contactForm .leftSide img {
    height: 400px;
  }
  .ambassadorCont .contactForm form .heading {
    text-align: center;
    padding: 0.5em;
  }
  .ambassadorCont .contactForm form .inputGroup input,
  .ambassadorCont .contactForm form .inputGroup textarea,
  .ambassadorCont .contactForm form .inputGroup button {
    margin: 0;
  }
  .ambassadorCont .contactForm form .socialMedia {
    margin: 2em 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .ambassadorCont .contactForm {
    padding: 5em 20px;
  }
  .ambassadorCont .contactForm .leftSide {
    margin: auto;
  }
  .ambassadorCont .contactForm .leftSide img {
    width: 100%;
    height: auto;
  }
  .ambassadorCont .contactForm form .heading {
    padding: 0.5em;
  }
}
/*# sourceMappingURL=CampusAmbassadorComponent.css.map */
/* No CSS *//*# sourceMappingURL=TicketBookingComponent.css.map */
.highlightsCont {
  margin: 3em 20px;
}

.highlightsCont .videosList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 3em;
  gap: 3em;
  margin: 3em 20px;
}

.highlightsCont .videosList .singleVideo iframe {
  width: 100%;
  height: 250px;
  border-radius: 10px;
}

.highlightsCont .videosList .singleVideo .title {
  margin-top: 0.75em;
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .highlightsCont .videosList {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .highlightsCont .videosList {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    margin: 3em 5em;
  }
}
/*# sourceMappingURL=EventHighlightsComponent.css.map */
.submitBtn {
  margin: 2em 0;
  text-align: center;
}

.submitBtn button {
  font-size: 1.2em;
  background-color: #e62b1e;
  color: #fff;
  padding: 15px 45px;
  border-radius: 30px;
  border: none;
  outline: none;
  cursor: pointer;
}

.blogListCont {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  grid-gap: 0;
  margin: 2em 1em;
}

.blogListCont .singleBlog {
  color: #000;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.3s all ease;
}

.blogListCont .singleBlog .blogImage {
  width: calc(100vw - 5em);
  transition: 0.3s all ease;
}

.blogListCont .singleBlog .blogContent {
  width: auto;
  padding: 20px 0;
}

.blogListCont .singleBlog .blogContent .blogDate {
  font-size: 0.95em;
  color: #616161;
}

.blogListCont .singleBlog .blogContent .blogHeading {
  margin: 0.5em 0 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.blogListCont .singleBlog .blogContent .blogAuthor {
  color: #616161;
  font-size: 0.9rem;
  margin-top: 0.5em;
}

.blogListCont .singleBlog .blogContent .blogDesc {
  font-size: 0.95em;
}

.blogListCont .singleBlog:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.blogListCont .singleBlog:hover .blogImage {
  -webkit-filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(0.8);
          filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(0.8);
}

@media only screen and (min-width: 768px) {
  .blogListCont {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    grid-gap: 3em;
    margin: 2em 3em;
  }
  .blogListCont .singleBlog .blogImage {
    width: calc((100vw - 6em - 6em) / 2);
  }
}

@media only screen and (min-width: 992px) {
  .blogListCont {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    grid-gap: 3em;
    margin: 2em 3em;
  }
  .blogListCont .singleBlog .blogImage {
    width: calc((100vw - 6em - 6em) / 3);
  }
}
/*# sourceMappingURL=BlogListComponent.css.map */
.singleBlogCont {
  background-color: #ebf5fc;
}

.singleBlogCont .blogHeader {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (auto auto)[1];
      grid-template-columns: repeat(1, auto auto);
  margin: 0 5vw;
}

.singleBlogCont .blogHeader .blogDetails {
  justify-self: end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-right: 10px;
}

.singleBlogCont .blogHeader .blogDetails .blogTitle {
  font-weight: 600;
}

.singleBlogCont .blogHeader .blogImage {
  -ms-grid-column-align: end;
      justify-self: end;
}

.singleBlogCont .blogHeader .blogImage img {
  height: 60vh;
  width: auto;
  margin-top: 5vh;
  border-radius: 20px;
}

.singleBlogCont .blogMain {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (60.3vw 27vw)[1];
      grid-template-columns: repeat(1, 60.3vw 27vw);
  grid-gap: 2.7vw;
  margin: 5vh 5vw 0;
  padding-bottom: 5vh;
}

.singleBlogCont .blogMain .blogLeft .blogContent,
.singleBlogCont .blogMain .blogLeft .blogShare {
  background-color: #fff;
  padding: 2.7vw;
  border-radius: 20px;
}

.singleBlogCont .blogMain .blogLeft .blogContent p span,
.singleBlogCont .blogMain .blogLeft .blogShare p span {
  font-family: "Montserrat", sans-serif !important;
}

.singleBlogCont .blogMain .blogLeft .blogContent .shareButtons button,
.singleBlogCont .blogMain .blogLeft .blogShare .shareButtons button {
  margin-top: 1em;
}

.singleBlogCont .blogMain .blogLeft .blogContent .shareButtons button:not(:first-child),
.singleBlogCont .blogMain .blogLeft .blogShare .shareButtons button:not(:first-child) {
  margin-left: 10px;
}

.singleBlogCont .blogMain .blogLeft .blogShare {
  margin-top: 2.7vw;
}

.singleBlogCont .blogMain .blogRight {
  position: -webkit-sticky;
  position: sticky;
  top: calc(10vh + 2.7vw);
  background-color: #fff;
  padding: 2.7vw;
  border-radius: 20px;
  max-height: calc(85vh - 2.7vw);
  overflow: auto;
}

.singleBlogCont .blogMain .blogRight::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

.singleBlogCont .blogMain .blogRight::-webkit-scrollbar-track {
  width: 5px;
  background-color: #d6d5d5;
  border-radius: 20px;
}

.singleBlogCont .blogMain .blogRight::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #000;
  border-radius: 20px;
}

.singleBlogCont .blogMain .blogRight .blogList h3 {
  font-size: 1.7em;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList {
  display: -webkit-flex;
  display: flex;
  color: #000;
  text-decoration: none;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList .singleBlogListImage img {
  height: 80px;
  width: 80px;
  border-radius: 7px;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList .singleBlogListContent {
  margin-left: 1em;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList .singleBlogListContent .blogTitle {
  font-size: 1.2em;
  font-weight: 600;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList .singleBlogListContent .blogDate {
  font-size: 0.85em;
  color: #616161;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList:not(:first-child) {
  margin-top: 2.7vw;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList:not(:last-child) {
  position: relative;
}

.singleBlogCont .blogMain .blogRight .blogList .singleBlogList:not(:last-child)::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a8a4a4;
  bottom: -1.35vw;
}

@media only screen and (max-width: 992px) {
  .singleBlogCont {
    background-color: #ebf5fc;
  }
  .singleBlogCont .blogHeader {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin: 0;
  }
  .singleBlogCont .blogHeader .blogDetails {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #fff;
    width: calc(100vw - 20px);
    z-index: 2;
  }
  .singleBlogCont .blogHeader .blogImage {
    position: relative;
  }
  .singleBlogCont .blogHeader .blogImage img {
    margin-top: 0;
    height: 90vh;
    width: 100%;
    border-radius: 0;
  }
  .singleBlogCont .blogHeader .blogImage::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .singleBlogCont .blogMain {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.35vw;
    margin: 2.5vw 2.5vw 0;
    padding-bottom: 2.5vw;
  }
  .singleBlogCont .blogMain .blogLeft .blogContent {
    margin: 0 0 2.7vw;
    padding: 20px;
    font-size: 0.9em;
  }
  .singleBlogCont .blogMain .blogLeft .blogShare {
    margin: 0 0 0;
    padding: 20px;
  }
  .singleBlogCont .blogMain .blogRight {
    position: static;
    margin-top: 1.35vw;
    padding: 20px;
    height: auto;
  }
  .singleBlogCont .blogMain .blogRight .blogList h3 {
    font-size: 1.5em;
  }
  .singleBlogCont .blogMain .blogRight .blogList .singleBlogList:not(:first-child) {
    margin-top: 5.4vw;
  }
  .singleBlogCont .blogMain .blogRight .blogList .singleBlogList:not(:last-child)::after {
    bottom: -2.7vw;
  }
}
/*# sourceMappingURL=SingleBlogComponent.css.map */
.guidelinesCont {
  margin: 3em 5vw;
}

.guidelinesCont .heading {
  text-align: center;
  font-size: 1.7em;
}

.guidelinesCont section {
  margin: 2em 0;
}

.guidelinesCont section .subheading {
  font-size: 1.3em;
}

.guidelinesCont section p ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.guidelinesCont section p ol li {
  font-size: 0.9em;
  line-height: 1.6em;
}

.guidelinesCont section p ol li a {
  color: #e62b1e;
}

.guidelinesCont section p ol li strong {
  font-weight: 600;
}

.guidelinesCont section p ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.guidelinesCont section p ol li:not(:first-child) {
  margin-top: 0.7em;
}

.guidelinesCont section a {
  text-decoration: none;
  color: #e62b1e;
}

.guidelinesCont section a button {
  margin-top: 1em;
  padding: 12px 30px;
  font-size: 1em;
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #f8c3c0;
  min-width: 150px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.guidelinesCont section a:hover button {
  background-color: #fff;
  border-color: #e62b1e;
}

@media only screen and (min-width: 992px) {
  .guidelinesCont {
    margin: 3em 10vw;
  }
  .guidelinesCont .heading {
    font-size: 2em;
  }
  .guidelinesCont section p ol {
    margin: 1.2em 2em 0;
  }
  .guidelinesCont section p ol li {
    font-size: 0.9em;
  }
  .guidelinesCont section p ol li:not(:first-child) {
    margin-top: 0.7em;
  }
}
/*# sourceMappingURL=BlogGuidelinesComponent.css.map */
.submitCont {
  margin: 5em 0;
}

.submitCont .instructions {
  padding: 0 0 0 20px;
  margin: 0 auto 3em;
}

.submitCont .instructions h3 {
  font-weight: 500;
  font-size: 1.5em;
}

.submitCont .instructions ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.submitCont .instructions ol li {
  font-size: 0.9em;
  line-height: 1.6em;
}

.submitCont .instructions ol li a {
  color: #e62b1e;
}

.submitCont .instructions ol li strong {
  font-weight: 600;
}

.submitCont .instructions ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.submitCont .instructions ol li:not(:first-child) {
  margin-top: 0.7em;
}

.submitCont form {
  margin: 3em auto 0;
  width: 90vw;
}

.submitCont form .formRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.submitCont form .formRow:not(:first-child) {
  margin-top: 1.5em;
}

.submitCont form .inputGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.submitCont form .inputGroup #title,
.submitCont form .inputGroup #upload {
  width: 100%;
}

.submitCont form .inputGroup label {
  font-weight: 600;
  margin-bottom: 0.4em;
}

.submitCont form .inputGroup input,
.submitCont form .inputGroup select {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
}

.submitCont form .inputGroup select {
  padding: 9px;
}

.submitCont form .inputGroup button {
  background-color: #fff;
  color: #e62b1e;
  font-weight: 600;
  border: 2px solid #e62b1e;
  padding: 10px 45px;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  margin: 2em auto 0;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.submitCont form .inputGroup button:hover {
  background-color: #e62b1e;
  color: #fff;
}

.submitCont form .inputGroup.progressBar {
  width: 100%;
  margin: 2em auto 0;
}

@media only screen and (min-width: 992px) {
  .submitCont {
    margin: 5em;
  }
  .submitCont .instructions {
    padding: 0 0 0 50px;
    margin-bottom: 3em;
  }
  .submitCont .instructions h3 {
    font-weight: 500;
    font-size: 1.5em;
  }
  .submitCont .instructions ol {
    margin: 1.2em 2em 0;
  }
  .submitCont .instructions ol li {
    font-size: 0.9em;
  }
  .submitCont .instructions ol li:not(:first-child) {
    margin-top: 0.7em;
  }
  .submitCont form {
    width: 75vw;
  }
  .submitCont form .formRow.singleInput {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .submitCont form .formRow.twoInputs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .submitCont form .inputGroup.progressBar {
    width: 50%;
  }
}
/*# sourceMappingURL=SubmitBlogComponent.css.map */
.moodBoardListCont {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  grid-gap: 0;
  margin: 2em 1em;
}

.moodBoardListCont .singleMoodBoard {
  color: #000;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.3s all ease;
}

.moodBoardListCont .singleMoodBoard .moodBoardImage {
  border-radius: 0.5em;
  height: 250px;
  width: 100%;
  transition: 0.3s all ease;
}

.moodBoardListCont .singleMoodBoard .moodBoardContent {
  width: auto;
  padding: 20px 0;
}

.moodBoardListCont .singleMoodBoard .moodBoardDate {
  font-size: 0.95em;
  color: #616161;
}

.moodBoardListCont .singleMoodBoard .moodBoardHeading {
  margin: 0.5em 0 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.moodBoardListCont .singleMoodBoard .moodBoardAuthor {
  color: #616161;
  font-size: 0.9rem;
  margin-top: 0.5em;
}

.moodBoardListCont .singleMoodBoard:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.moodBoardListCont .singleMoodBoard:hover .moodBoardImage {
  -webkit-filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(0.8);
          filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(0.8);
}

@media only screen and (min-width: 768px) {
  .moodBoardListCont {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    grid-gap: 0em;
  }
  .moodBoardListCont .singleMoodBoard .moodBoardImage {
    width: calc((100vw - 6em - 6em) / 2);
  }
}

@media only screen and (min-width: 992px) {
  .moodBoardListCont {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    grid-gap: 3em;
  }
  .moodBoardListCont .singleMoodBoard .moodBoardImage {
    width: calc((100vw - 6em - 6em) / 3);
  }
}
/*# sourceMappingURL=MoodBoardList.css.map */
.singleMoodBoardCont {
  background-color: #ebf5fc;
}

.singleMoodBoardCont .moodBoardHeader {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (auto auto)[1];
      grid-template-columns: repeat(1, auto auto);
  margin: 0 5vw;
}

.singleMoodBoardCont .moodBoardDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  justify-self: end;
}

.singleMoodBoardCont .moodBoardDetails .moodBoardTitle {
  font-weight: 600;
}

.singleMoodBoardCont .moodBoardImage {
  -ms-grid-column-align: end;
      justify-self: end;
}

.singleMoodBoardCont .moodBoardImage img {
  height: 70vh;
  width: auto;
  margin-top: 5vh;
  border-radius: 20px;
}

.moodBoardMain {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (60.3vw 27vw)[1];
      grid-template-columns: repeat(1, 60.3vw 27vw);
  grid-gap: 2.7vw;
  margin: 5vh 5vw 0;
  padding-bottom: 5vh;
}

.moodBoardMain .moodBoardLeft .moodBoardShare {
  background-color: #fff;
  padding: 2.7vw;
  border-radius: 20px;
  margin-top: 2, 7vw;
}

.moodBoardMain .moodBoardLeft .moodBoardShare .shareButtons button {
  margin-top: 1em;
}

.moodBoardMain .moodBoardLeft .moodBoardShare .shareButtons button:not(:first-child) {
  margin-left: 10px;
}

.moodBoardMain .moodBoardRight {
  position: -webkit-sticky;
  position: sticky;
  top: calc(10vh + 2.7vw);
  background-color: #fff;
  padding: 2.7vw;
  border-radius: 20px;
  max-height: calc(85vh - 2.7vw);
  overflow: auto;
}

.moodBoardMain .moodBoardRight::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

.moodBoardMain .moodBoardRight::-webkit-scrollbar-track {
  width: 5px;
  background-color: #d6d5d5;
  border-radius: 20px;
}

.moodBoardMain .moodBoardRight::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #000;
  border-radius: 20px;
}

.moodBoardMain .moodBoardRight .moodBoardList h3 {
  font-size: 1.7em;
}

.moodBoardMain .moodBoardRight .singleMoodBoardList {
  display: -webkit-flex;
  display: flex;
  color: #000;
  text-decoration: none;
}

.moodBoardMain .moodBoardRight .singleMoodBoardList .singleMoodBoardListImage img {
  height: 80px;
  width: 80px;
  border-radius: 7px;
}

.moodBoardMain .moodBoardRight .singleMoodBoardList .singleMoodBoardContent {
  margin-left: 1em;
}

.moodBoardMain .moodBoardRight .singleMoodBoardList .singleMoodBoardContent .moodBoardTitle {
  font-size: 0.85em;
  color: #616161;
}

.moodBoardMain .moodBoardRight .singleMoodBoardList .moodBoardDate {
  font-size: 0.85em;
  color: #616161;
}

.moodBoardMain .moodBoardRight:not(:last-child) {
  position: relative;
}

.moodBoardMain .moodBoardRight:not(:last-child)::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #a8a4a4;
  bottom: -1.35vw;
}

@media only screen and (max-width: 992px) {
  .singleMoodBoardCont {
    background-color: #ebf5fc;
  }
  .singleMoodBoardCont .moodBoardHeader {
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .singleMoodBoardCont .moodBoardImage img {
    height: 100%;
    width: 100%;
    margin-top: 5vh;
    padding: 0 1em 1em 1em;
    border-radius: 20px;
  }
  .moodBoardMain {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.35vw;
    margin: 2.5vw 2.5vw 0;
    padding-bottom: 2.5vw;
  }
  .moodBoardMain .moodBoardLeft .moodBoardShare {
    margin: 0 0 0;
    padding: 20px;
  }
  .moodBoardMain .moodBoardRight {
    position: static;
    margin-top: 1.35vw;
    padding: 20px;
    height: auto;
  }
  .moodBoardMain .moodBoardRight .moodBoardList h3 {
    font-size: 1.5em;
  }
  .moodBoardMain .moodBoardRight .moodBoardList .singleMoodBoardList:not(:first-child) {
    margin-top: 5.4vw;
  }
  .moodBoardMain .moodBoardRight .moodBoardList .singleMoodBoardList:not(:last-child)::after {
    bottom: -2.7vw;
  }
}
/*# sourceMappingURL=SingleMoodBoard.css.map */
.addMoodBoardCont {
  margin: 3em 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.addMoodBoardCont form {
  font-size: 0.9em;
}

.addMoodBoardCont form .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 1em;
  padding: 0.5em;
  text-align: center;
}

.addMoodBoardCont form .inputRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5em;
  width: 70vw;
  margin: auto;
}

.addMoodBoardCont form .inputRow .inputGroup {
  width: 100%;
}

.addMoodBoardCont form .inputRow .inputGroup input,
.addMoodBoardCont form .inputRow .inputGroup textarea {
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 500;
  height: 3.5em;
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  border: none;
  outline: none;
}

.addMoodBoardCont form .inputRow .inputGroup input::-webkit-input-placeholder,
.addMoodBoardCont form .inputRow .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addMoodBoardCont form .inputRow .inputGroup input:-ms-input-placeholder,
.addMoodBoardCont form .inputRow .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addMoodBoardCont form .inputRow .inputGroup input::-webkit-input-placeholder, .addMoodBoardCont form .inputRow .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addMoodBoardCont form .inputRow .inputGroup input:-ms-input-placeholder, .addMoodBoardCont form .inputRow .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addMoodBoardCont form .inputRow .inputGroup input::placeholder,
.addMoodBoardCont form .inputRow .inputGroup textarea::placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addMoodBoardCont form .inputRow .inputGroup button {
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  padding: 15px;
  border-radius: 25px;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.addMoodBoardCont form .inputRow.single {
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  width: 80%;
  margin: 1.5em auto 0;
}
/*# sourceMappingURL=AddMoodBoard.css.map */
.guidelinesCont {
  margin: 3em 15px;
}

.guidelinesCont .heading {
  text-align: center;
  font-size: 1.7em;
}

.guidelinesCont section {
  margin: 2em 0;
}

.guidelinesCont section .subheading {
  font-size: 1.3em;
  font-weight: 600;
}

.guidelinesCont section .subSubheading {
  font-size: 1rem;
  margin: 1em 0 0 0.5em;
  font-weight: 500;
}

.guidelinesCont section p.left {
  margin: 1.2em 1em 0;
  font-size: 0.9rem;
}

.guidelinesCont section p ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.guidelinesCont section p ol li {
  font-size: 0.9rem;
  line-height: 1.6em;
}

.guidelinesCont section p ol li a {
  color: #e62b1e;
}

.guidelinesCont section p ol li strong {
  font-weight: 600;
}

.guidelinesCont section p ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.guidelinesCont section p ol li:not(:first-child) {
  margin-top: 0.7em;
}

.guidelinesCont section a {
  text-decoration: none;
  color: #e62b1e;
}

.guidelinesCont section a button {
  margin-top: 1em;
  padding: 12px 30px;
  font-size: 1em;
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #f8c3c0;
  min-width: 150px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.guidelinesCont section a:hover button {
  background-color: #fff;
  border-color: #e62b1e;
}

@media only screen and (min-width: 992px) {
  .guidelinesCont {
    margin: 3em 10vw;
  }
  .guidelinesCont .heading {
    font-size: 2em;
  }
  .guidelinesCont section p ol {
    margin: 1.2em 2em 0;
  }
  .guidelinesCont section p ol li {
    font-size: 0.9em;
  }
  .guidelinesCont section p ol li:not(:first-child) {
    margin-top: 0.7em;
  }
}
/*# sourceMappingURL=RegisterGuidelinesComponent.css.map */
.submitCont {
  margin: 5em 0;
}

.submitCont .lineTitle {
  margin: 1.5em 0 0 0;
}

.submitCont .instructions {
  padding: 0 0 0 20px;
  margin: 0 auto 3em;
}

.submitCont .instructions h3 {
  font-weight: 500;
  font-size: 1.5em;
}

.submitCont .instructions ol {
  margin: 1.2em 1em 0;
  list-style-type: none;
}

.submitCont .instructions ol li {
  font-size: 0.9em;
  line-height: 1.6em;
}

.submitCont .instructions ol li a {
  color: #e62b1e;
}

.submitCont .instructions ol li strong {
  font-weight: 600;
}

.submitCont .instructions ol li::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 1.1em;
  padding-right: 0.5em;
}

.submitCont .instructions ol li:not(:first-child) {
  margin-top: 0.7em;
}

.submitCont form {
  margin: 3em 15px;
  width: 90vw;
}

.submitCont form .formRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5em;
}

.submitCont form .inputGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.submitCont form .inputGroup #title,
.submitCont form .inputGroup #upload {
  width: 100%;
}

.submitCont form .inputGroup label {
  font-weight: 600;
  margin-bottom: 0.4em;
}

.submitCont form .inputGroup input,
.submitCont form .inputGroup select {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 3px;
  outline: none;
}

.submitCont form .inputGroup select {
  padding: 9px;
}

.submitCont form .inputGroup button {
  background-color: #fff;
  color: #e62b1e;
  font-weight: 600;
  border: 2px solid #e62b1e;
  padding: 10px 45px;
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
  margin: 2em auto 0;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.submitCont form .inputGroup button:hover {
  background-color: #e62b1e;
  color: #fff;
}

.submitCont form .inputGroup.progressBar {
  width: 100%;
  margin: 2em auto 0;
}

@media only screen and (min-width: 992px) {
  .submitCont {
    margin: 5em;
  }
  .submitCont .lineTitle {
    margin-left: 20px;
  }
  .submitCont .instructions {
    padding: 0 0 0 50px;
    margin-bottom: 3em;
  }
  .submitCont .instructions h3 {
    font-weight: 500;
    font-size: 1.5em;
  }
  .submitCont .instructions ol {
    margin: 1.2em 2em 0;
  }
  .submitCont .instructions ol li {
    font-size: 0.9em;
  }
  .submitCont .instructions ol li:not(:first-child) {
    margin-top: 0.7em;
  }
  .submitCont form {
    margin: 3em 0;
    width: 75vw;
  }
  .submitCont form .formRow {
    margin-left: 50%;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    width: 100%;
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
  .submitCont form .inputGroup.progressBar {
    width: 50%;
  }
}
/*# sourceMappingURL=RegisterFormComponent.css.map */
.loginCont {
  margin: 3em 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.loginCont form {
  font-size: 0.9em;
}

.loginCont form .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 1em;
  padding: 0.5em;
  text-align: center;
}

.loginCont form .inputGroup {
  width: 30vw;
  margin: auto;
}

.loginCont form .inputGroup input,
.loginCont form .inputGroup select,
.loginCont form .inputGroup textarea {
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 500;
  height: 3.5em;
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  border: none;
  outline: none;
}

.loginCont form .inputGroup input::-webkit-input-placeholder,
.loginCont form .inputGroup select::-webkit-input-placeholder,
.loginCont form .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.loginCont form .inputGroup input:-ms-input-placeholder,
.loginCont form .inputGroup select:-ms-input-placeholder,
.loginCont form .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.loginCont form .inputGroup input::-webkit-input-placeholder, .loginCont form .inputGroup select::-webkit-input-placeholder, .loginCont form .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.loginCont form .inputGroup input:-ms-input-placeholder, .loginCont form .inputGroup select:-ms-input-placeholder, .loginCont form .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.loginCont form .inputGroup input::placeholder,
.loginCont form .inputGroup select::placeholder,
.loginCont form .inputGroup textarea::placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.loginCont form .inputGroup select {
  height: 100%;
}

.loginCont form .inputGroup textarea {
  height: 200px;
}

.loginCont form .inputGroup button {
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  padding: 15px;
  border-radius: 25px;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.loginCont form .inputGroup:not(:first-child) {
  margin-top: 1em;
}

@media only screen and (max-width: 992px) {
  .loginCont form .heading {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  .loginCont form .inputGroup {
    width: 80vw;
  }
}
/*# sourceMappingURL=LoginComponent.css.map */
.dashboardCont {
  margin: 3em;
}

.dashboardCont .buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.dashboardCont .buttons a button {
  padding: 10px 45px;
  background-color: #f8c3c0;
  color: #e62b1e;
  border: 1px solid #f8c3c0;
  font-weight: 600;
  border-radius: 5px;
  width: 25vw;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.dashboardCont .buttons a button:hover {
  background-color: #fff;
  border: 1px solid #e62b1e;
  color: #e62b1e;
}

.dashboardCont .buttons a:not(:first-child) {
  margin-left: 2em;
}

.dashboardCont .buttons:not(:first-child) {
  margin-top: 2em;
}

@media only screen and (max-width: 768px) {
  .dashboardCont {
    margin: 3em 2em;
  }
  .dashboardCont .buttons {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .dashboardCont .buttons a button {
    min-width: 70vw;
  }
  .dashboardCont .buttons a:not(:first-child) {
    margin: 2em 0 0 0;
  }
}
/*# sourceMappingURL=DashboardComponent.css.map */
.dashboardBlogListCont {
  margin: 3em 1em;
}

.dashboardBlogListCont .blogs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.dashboardBlogListCont .blogs .singleBlog {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.dashboardBlogListCont .blogs .singleBlog .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.dashboardBlogListCont .blogs .singleBlog .left .name {
  font-weight: 500;
}

.dashboardBlogListCont .blogs .singleBlog .left .date {
  font-size: 0.85em;
}

.dashboardBlogListCont .blogs .singleBlog .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.dashboardBlogListCont .blogs .singleBlog .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.dashboardBlogListCont .blogs .singleBlog:nth-child(4n + 1), .dashboardBlogListCont .blogs .singleBlog:nth-child(4n + 4) {
  background-color: #fff;
}

.dashboardBlogListCont .blogs .singleBlog:nth-child(even) {
  background-color: #f2f2f2;
}

.dashboardBlogListCont .blogs .singleBlog:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .dashboardBlogListCont {
    margin: 3em;
  }
  .dashboardBlogListCont .blogs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .dashboardBlogListCont .blogs .singleBlog {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .dashboardBlogListCont .blogs .singleBlog .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
  .dashboardBlogListCont .blogs .singleBlog:nth-child(even) {
    background-color: #fff;
  }
  .dashboardBlogListCont .blogs .singleBlog:nth-child(4n + 1), .dashboardBlogListCont .blogs .singleBlog:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .dashboardBlogListCont .blogs .singleBlog:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=BlogListComponent.css.map */
.dashboardMoodBoardListCont {
  margin: 3em 1em;
}

.dashboardMoodBoardListCont .moodBoards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard .left .name {
  font-weight: 500;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard .left .date {
  font-size: 0.85em;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard .right button.success {
  background-color: #cff7e4;
  color: #10d876;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard:nth-child(4n + 1), .dashboardMoodBoardListCont .moodBoards .singleMoodBoard:nth-child(4n + 4) {
  background-color: #fff;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard:nth-child(even) {
  background-color: #f2f2f2;
}

.dashboardMoodBoardListCont .moodBoards .singleMoodBoard:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .dashboardMoodBoardListCont {
    margin: 3em;
  }
  .dashboardMoodBoardListCont .moodBoards {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .dashboardMoodBoardListCont .moodBoards .singleMoodBoard {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .dashboardMoodBoardListCont .moodBoards .singleMoodBoard .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
  .dashboardMoodBoardListCont .moodBoards .singleMoodBoard:nth-child(even) {
    background-color: #fff;
  }
  .dashboardMoodBoardListCont .moodBoards .singleMoodBoard:nth-child(4n + 1), .dashboardMoodBoardListCont .moodBoards .singleMoodBoard:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .dashboardMoodBoardListCont .moodBoards .singleMoodBoard:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=MoodBoardListComponent.css.map */
.messagesListCont {
  margin: 3em 1em;
}

.messagesListCont .messages {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.messagesListCont .messages .singleMessage {
  padding: 20px;
}

.messagesListCont .messages .singleMessage .up .name {
  font-size: 1.2em;
  font-weight: 600;
}

.messagesListCont .messages .singleMessage .up .date,
.messagesListCont .messages .singleMessage .up .email {
  font-size: 0.85em;
}

.messagesListCont .messages .singleMessage .down {
  margin-top: 1em;
}

.messagesListCont .messages .singleMessage .down p {
  font-size: 0.9em;
}

.messagesListCont .messages .singleMessage:nth-child(4n + 1), .messagesListCont .messages .singleMessage:nth-child(4n + 4) {
  background-color: #fff;
}

.messagesListCont .messages .singleMessage:nth-child(even) {
  background-color: #f2f2f2;
}

.messagesListCont .messages .singleMessage:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .messagesListCont {
    margin: 3em;
  }
  .messagesListCont .messages {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .messagesListCont .messages .singleMessage:nth-child(even) {
    background-color: #fff;
  }
  .messagesListCont .messages .singleMessage:nth-child(4n + 1), .messagesListCont .messages .singleMessage:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .messagesListCont .messages .singleMessage:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=MessagesListComponent.css.map */
.addBlogCont {
  margin: 3em 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.addBlogCont form {
  font-size: 0.9em;
}

.addBlogCont form .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.5em;
  margin-bottom: 1em;
  padding: 0.5em;
  text-align: center;
}

.addBlogCont form .inputRow {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5em;
  width: 70vw;
  margin: auto;
}

.addBlogCont form .inputRow .inputGroup {
  width: 100%;
}

.addBlogCont form .inputRow .inputGroup input,
.addBlogCont form .inputRow .inputGroup textarea {
  background-color: #f8c3c0;
  color: #e62b1e;
  font-weight: 500;
  height: 3.5em;
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  border: none;
  outline: none;
}

.addBlogCont form .inputRow .inputGroup input::-webkit-input-placeholder,
.addBlogCont form .inputRow .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addBlogCont form .inputRow .inputGroup input:-ms-input-placeholder,
.addBlogCont form .inputRow .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addBlogCont form .inputRow .inputGroup input::-webkit-input-placeholder, .addBlogCont form .inputRow .inputGroup textarea::-webkit-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addBlogCont form .inputRow .inputGroup input:-ms-input-placeholder, .addBlogCont form .inputRow .inputGroup textarea:-ms-input-placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addBlogCont form .inputRow .inputGroup input::placeholder,
.addBlogCont form .inputRow .inputGroup textarea::placeholder {
  color: #e62b1e;
  font-weight: 500;
}

.addBlogCont form .inputRow .inputGroup textarea {
  height: 200px;
}

.addBlogCont form .inputRow .inputGroup button {
  background-color: #e62b1e;
  color: #fff;
  font-weight: 600;
  padding: 15px;
  border-radius: 25px;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.addBlogCont form .inputRow.single {
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  width: 50%;
  margin: 1.5em auto 0;
}

.addBlogCont form .summernote {
  width: 70vw;
  margin: 1em auto 0;
}

.addBlogCont form .summernote > div > div {
  border-radius: 25px;
  padding: 10px;
}

@media only screen and (max-width: 992px) {
  .addBlogCont form .heading {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  .addBlogCont form .inputRow {
    width: 90vw;
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .addBlogCont form .summernote {
    width: 90vw;
  }
}
/*# sourceMappingURL=AddBlogComponent.css.map */
.openMicListCont {
  margin: 3em 1em;
}

.openMicListCont .blogs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.openMicListCont .blogs .singleBlog {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.openMicListCont .blogs .singleBlog .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.openMicListCont .blogs .singleBlog .left .name {
  font-weight: 500;
}

.openMicListCont .blogs .singleBlog .left .date {
  font-size: 0.85em;
}

.openMicListCont .blogs .singleBlog .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.openMicListCont .blogs .singleBlog .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.openMicListCont .blogs .singleBlog:nth-child(4n + 1), .openMicListCont .blogs .singleBlog:nth-child(4n + 4) {
  background-color: #fff;
}

.openMicListCont .blogs .singleBlog:nth-child(even) {
  background-color: #f2f2f2;
}

.openMicListCont .blogs .singleBlog:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .openMicListCont {
    margin: 3em;
  }
  .openMicListCont .blogs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .openMicListCont .blogs .singleBlog {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .openMicListCont .blogs .singleBlog .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
  .openMicListCont .blogs .singleBlog:nth-child(even) {
    background-color: #fff;
  }
  .openMicListCont .blogs .singleBlog:nth-child(4n + 1), .openMicListCont .blogs .singleBlog:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .openMicListCont .blogs .singleBlog:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=OpenMicListComponent.css.map */
.speakersListCont {
  margin: 3em 1em;
}

.speakersListCont .blogs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.speakersListCont .blogs .singleBlog {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.speakersListCont .blogs .singleBlog .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.speakersListCont .blogs .singleBlog .left .name {
  font-weight: 500;
}

.speakersListCont .blogs .singleBlog .left .date {
  font-size: 0.85em;
}

.speakersListCont .blogs .singleBlog .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.speakersListCont .blogs .singleBlog .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.speakersListCont .blogs .singleBlog:nth-child(4n + 1), .speakersListCont .blogs .singleBlog:nth-child(4n + 4) {
  background-color: #fff;
}

.speakersListCont .blogs .singleBlog:nth-child(even) {
  background-color: #f2f2f2;
}

.speakersListCont .blogs .singleBlog:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .speakersListCont {
    margin: 3em;
  }
  .speakersListCont .blogs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .speakersListCont .blogs .singleBlog {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .speakersListCont .blogs .singleBlog .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
  .speakersListCont .blogs .singleBlog:nth-child(even) {
    background-color: #fff;
  }
  .speakersListCont .blogs .singleBlog:nth-child(4n + 1), .speakersListCont .blogs .singleBlog:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .speakersListCont .blogs .singleBlog:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=SpeakersListComponent.css.map */
.caListCont {
  margin: 3em 1em;
}

.caListCont .blogs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.caListCont .blogs .singleBlog {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.caListCont .blogs .singleBlog .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.caListCont .blogs .singleBlog .left .name {
  font-weight: 500;
}

.caListCont .blogs .singleBlog .left .date {
  font-size: 0.85em;
}

.caListCont .blogs .singleBlog .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.caListCont .blogs .singleBlog .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.caListCont .blogs .singleBlog:nth-child(4n + 1), .caListCont .blogs .singleBlog:nth-child(4n + 4) {
  background-color: #fff;
}

.caListCont .blogs .singleBlog:nth-child(even) {
  background-color: #f2f2f2;
}

.caListCont .blogs .singleBlog:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .caListCont {
    margin: 3em;
  }
  .caListCont .blogs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .caListCont .blogs .singleBlog {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .caListCont .blogs .singleBlog .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
  .caListCont .blogs .singleBlog:nth-child(even) {
    background-color: #fff;
  }
  .caListCont .blogs .singleBlog:nth-child(4n + 1), .caListCont .blogs .singleBlog:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .caListCont .blogs .singleBlog:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=CampusAmbassadorComponent.css.map */
.promocodeBookingsCont {
  margin: 3em 1em;
}

.promocodeBookingsCont .blogs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
  margin: 2em 40px;
}

.promocodeBookingsCont .blogs .singleBlog {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #f2f2f2;
}

.promocodeBookingsCont .blogs .singleBlog .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.promocodeBookingsCont .blogs .singleBlog .left .name {
  font-weight: 500;
}

.promocodeBookingsCont .blogs .singleBlog .left .date {
  font-size: 0.85em;
}

.promocodeBookingsCont .blogs .singleBlog .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.promocodeBookingsCont .blogs .singleBlog .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.promocodeBookingsCont .blogs .singleBlog:nth-child(even) {
  background-color: #fff;
}

.promocodeBookingsCont .blogs .singleBlog:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .promocodeBookingsCont {
    margin: 3em;
  }
  .promocodeBookingsCont .blogs {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    margin: 2em 0 2em 70px;
  }
  .promocodeBookingsCont .blogs .singleBlog {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .promocodeBookingsCont .blogs .singleBlog .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
}
/*# sourceMappingURL=PromocodeBookingsComponent.css.map */
.registrationsListCont {
  margin: 3em 1em;
}

.registrationsListCont .blogs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
      grid-template-columns: repeat(1, 1fr);
}

.registrationsListCont .blogs .singleBlog {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.registrationsListCont .blogs .singleBlog .left .title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.registrationsListCont .blogs .singleBlog .left .name {
  font-weight: 500;
}

.registrationsListCont .blogs .singleBlog .left .date {
  font-size: 0.85em;
}

.registrationsListCont .blogs .singleBlog .right {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-top: 1em;
}

.registrationsListCont .blogs .singleBlog .right button {
  padding: 10px 30px;
  font-size: 0.9em;
  font-weight: 600;
  background-color: #f8c3c0;
  color: #e62b1e;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.registrationsListCont .blogs .singleBlog:nth-child(4n + 1), .registrationsListCont .blogs .singleBlog:nth-child(4n + 4) {
  background-color: #fff;
}

.registrationsListCont .blogs .singleBlog:nth-child(even) {
  background-color: #f2f2f2;
}

.registrationsListCont .blogs .singleBlog:hover {
  background-color: #ececec;
}

@media only screen and (min-width: 768px) {
  .caListCont {
    margin: 3em;
  }
  .caListCont .blogs {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
  .caListCont .blogs .singleBlog {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
  }
  .caListCont .blogs .singleBlog .right {
    -ms-grid-row-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin-top: 0;
  }
  .caListCont .blogs .singleBlog:nth-child(even) {
    background-color: #fff;
  }
  .caListCont .blogs .singleBlog:nth-child(4n + 1), .caListCont .blogs .singleBlog:nth-child(4n + 4) {
    background-color: #f2f2f2;
  }
  .caListCont .blogs .singleBlog:hover {
    background-color: #ececec;
  }
}
/*# sourceMappingURL=RegistrationsComponent.css.map */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

/*
body {
  position: relative;
  background-image: url("./img/coming-soon.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
body::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.App {
  text-align: center;
}
.App img {
  height: 100px;
  margin-bottom: 3em;
}
.App h1 {
  color: #fff;
  font-size: 4em;
}
.App .socialMedia {
  margin-top: 2em;
  text-align: center;
}

.App .socialMedia a {
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.App .socialMedia a i {
  padding: 20px;
  border-radius: 50%;
  font-size: 1.3em;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.App .socialMedia a i.fa-facebook {
  background-color: #4267b2;
  color: #fff;
}

.App .socialMedia a i.fa-instagram {
  background-color: #f14164;
  color: #fff;
}

.App .socialMedia a i.fa-linkedin {
  background-color: #0077b5;
  color: #fff;
}

.App .socialMedia a i.fa-twitter {
  background-color: #00acee;
  color: #fff;
}

.App .socialMedia a:not(:first-child) {
  margin-left: 1em;
}
*/

/*
@media only screen and (max-width: 992px) {
  .App img {
    height: 100px;
  }
  .App h1 {
    font-size: 2.5em;
  }
  .App .socialMedia a i {
    font-size: 1em;
    padding: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .App img {
    width: 80%;
    height: auto;
  }
}
*/

::selection {
  background-color: #e62b1e;
  color: #fff;
}

.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.lineTitle {
  position: relative;
  font-family: "Montserrat", sans-serif;
  border-left: 4px solid #e62b1e;
  margin-left: 20px;
  font-size: 1.7em;
}

.lineTitle span {
  display: inline-block;
  background-color: #fff;
  padding: 0 10px 0 20px;
}

.lineTitle span strong {
  font-weight: bold;
}

.lineTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  width: 95%;
  border-top: 1px solid #c5c5c5;
  z-index: -1;
}

@media only screen and (min-width: 992px) {
  .lineTitle {
    margin-left: 50px;
  }
}

