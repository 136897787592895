
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter&family=Lugrasimo&family=Pangolin&display=swap');
.n-lineTitle{
    margin-top: 5rem;

}

.n-themeDiv{
    width: 100%;
    text-align: justify;
    /* background: rgb(108,0,0);
background: radial-gradient(circle, rgba(108,0,0,1) 0%, rgba(255,40,40,1) 57%, rgba(108,0,0,1) 100%); */
    background: rgb(108,0,0);
    background: linear-gradient(90deg, rgba(108,0,0,1) 0%, rgba(255,40,40,1) 57%, rgba(108,0,0,1) 100%);
background: rgb(191,0,0);
background: linear-gradient(90deg, rgba(191,0,0,1) 0%, rgba(255,40,40,1) 57%, rgba(191,0,0,1) 100%);
    margin: 3rem auto;
    padding: 5rem;
    
    /* border-radius: 9999px; */
    transition: 0.3s all ease-in-out;
}



.n-title{
    text-align: center;
    width: 50%;
    font-size: 2rem;
    font-family: 'Dancing Script', cursive;
   position: relative;
    background: white;
    color: rgb(207, 30, 30);
    /* color: linear-gradient(90deg, rgba(191,0,0,1) 0%, rgb(255, 114, 114) 57%, rgba(191,0,0,1) 100%); */
    margin: 0 auto;
    padding: 10px;
}

.n-themeDiv img{
    position: absolute;
    top: 18%;
    filter: invert();
    opacity: 0.2;
    width: 40%;
    height: 90%;
    left: 33%;
}
.n-themeDiv  > p{
    
    font-family: 'Lugrasimo', cursive;
    font-family: 'Pangolin', cursive;
    font-size: 1.2rem;
}
.n-themeDiv p::selection {
    background: white;
    color: red;
}
/* .n-themeDiv:hover{
    cursor: pointer;
    transform: scale(1.03);
} */


@media only screen and (max-width: 992px) {
    .n-themeDiv{
        padding: 10px;
    }

    .n-title{

        width:100%
    }
    .n-themeDiv  > p{
        font-size: 1rem;
    }
    .n-themeDiv img{
        /* width: 100% ; */
        height: 17rem;
        object-fit:cover;
    }

        .tedxbitd p {
            padding: 10px 10px 0 4px;
        }

}