.speakersList2023{
    margin-top: 3rem;
    /* height: 90vh; */
    width: 100%;
    z-index: 10;
    justify-content: space-evenly;
    display: grid;
    grid-template-columns: auto auto auto;
}

.speakerPopupContainer{
    width: 100vw;
    
    display: none;
   
}

.speakerPopupContainer.open{
    display: block;

}

.speakersList2023.open{
    position: relative;


}

.speakerPageCover{
    position: absolute;
    height: 200vh;
    width: 100%;
    top: -1%;
    background: black;
    opacity: 0.7;
    z-index: 1000;

    
}

.speakerPopupContainer>*{
    margin: 0 auto;
}

@media screen and (max-width: 700px) {
    .speakersList2023 {
        width: 100vw;
        overflow-x: hidden;
        grid-template-columns: auto;
    }
    .speakerPopupContainer{
        width: 100vw;;
        overflow-x: hidden;
    }
    .speakerPageCover{
        position: absolute;
        height: inherit;
        width: 100vw;
        overflow-x: hidden;
        right: 0;
        bottom: -150rem;
    }
    
}