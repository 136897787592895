.merchContent {
    height: 100%;
    width: 100%;
    /* overflow-x: hidden; */
    margin: auto;
}
.mainContent {
    display: flex;
    /* gap: 6em; */
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: auto;
    background: black;
    color: white;
    /* font-family:; */
    width: 100%;
}

.mainContent-top{
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.mainContent-top >  img {
    width: 30%;
}

.mainContent-top p {
    font-size: 2.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.sizeBtn {
    /* margin-top: 2em; */
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sizeBtn button {
    padding: 10px 30px;
    background-color: #e62b1e;
    color: #fff;
    border-radius: 7px;
    border: 2px solid #e62b1e;

    text-align: center;
    text-decoration: none;
    transition: 0.3s all ease;
}

.shirtBox{
    margin-bottom: 2rem;
    box-shadow: 2px 2px 10px black;
}

.sizeBtn button:hover {
    background: transparent;
    color: #e62b1e;
}

.shirtSizeBox {
    background: black;
    left: 30%;
    right: 30%;
    top: 25%;
    height: 50vh;
    border: 2px solid #e62b1e;
    position: fixed;
    z-index: 99;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}
.shirtSizeBox img {
    width: 70%;
}
.hideSizeBox {
    display: none;
}
.shirtDesc {
    padding-top: 2rem;
    padding-bottom: 4rem;
    height: inherit;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    /* justify-content: space-evenly; */
    flex-direction: column;
    
background: linear-gradient(90deg, rgba(119,1,1,1) 0%, rgba(168,0,0,1) 50%, rgba(119,1,1,1) 100%);
}
.shirtDesc .shirtBox {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 350px;
    /* height: 380px; */
    border:2px solid red;
    border-radius: 0.5em;
}

.shirtDesc span{
    color: white;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.shirtBoxContainer{
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    grid-template-columns: auto auto auto;

}

.shirtBox{
    background: white;
}

.shirtDesc .shirtBox img { 
 width: 100%;
}
.buyBtn {
    /* height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; */
    padding: 10px;
    background: black !important;
    border: none !important;
}
.sizeBtn{
    display: flex;
    /* align-items: center; */
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 100%;

}

.sizeBtn span{
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */

}

.sizeBtn span p:nth-child(1){
    font-size: 0.8rem;
    margin-right: 5px;
}

.boxDesc{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.boxDesc p:nth-child(1){
    /* font-size: 1.5rem; */
    background: black;
    background: none;
    background: linear-gradient(90deg, rgba(119,1,1,1) 0%, rgba(168,0,0,1) 50%, rgba(119,1,1,1) 100%);

    color: red;
    color: #e62b1e;
    color: white;
    width: 80%;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    text-align: center;
    font-weight: bold;
    padding: 0.5rem;
}

.boxDesc p:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: -0.5rem;
}

.boxDesc a button{
    background: black;
    color: white;
    /* font-weight: bold; */
    padding: .5rem;
    margin-top:-.5rem ;
    border: 2px solid red;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    animation-name: animateButton;
    animation: animateButton 2s infinite;
    
}

@keyframes animateButton{
    0%{
        /* background: red; */
        color: white;
    }
    
    25%{
        /* background: red;
        background: black; */
        color: red;
    }
    50%{
        /* background: black;
        background: red; */
        color: white;
    }
    75%{
        /* background: black;
        background: red; */
        color: red;
    }
    100%{
        /* background: black;
        background: red; */
        color: red;
        color: white;
    }


}

.boxDesc a button:hover{
    color: white;
    animation: none;
}

@media screen and (max-width: 700px) {
    .shirtBoxContainer{
        grid-template-columns: auto;
    }
    
    .shirtDesc{
        width: 100vw;
        overflow-x: hidden;
    }
        .mainContent-top{
            flex-direction: column;
            width: 100vw;
            overflow-x: hidden;
        }
                .mainContent-top>img{
                    width: 80%;
                    flex: 1;
                }
                .mainContent-top p{
                    flex: 2;
                    margin-top: 2rem;
                    font-size: 1.5rem;
                    text-align: center;
                }

                .shirtDesc span{
                    text-align: center;
                }
}
