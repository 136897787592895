.booking-card {
    width: 100%;
    overflow: hidden;
    box-shadow: none;
    position: relative;
  }
  
  .bookingCont .body .slabs-list .single-slab {
    box-shadow: none;
  }

 

  .content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    /* box-shadow: 0px 0px 10px 1px #000000ee; */
    border-radius: 5px;
  }
  
.back {
    background-color: #151515;
    position: absolute;
    width: 100%;
    height: 100%;
    /* backface-visibility: hidden; */
    /* -webkit-backface-visibility: hidden; */
    border-radius: 5px;
    overflow: hidden;
  }
  
  .back {
    /* width: 100%;
    height: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
  }
  
  .back::before {
    position: absolute;
    content: '';
    display: block;
    width: 160px;
    height: 160%;
    background: #e62b1e;
    
    animation: rotation_481 3700ms infinite linear;
  }
  
  .back-content {
   
    width: 99%;
    height: 99%;
   
  }
  
  
  
  @keyframes rotation_481 {
    0% {
      transform: rotateZ(0deg);
    }
  
    0% {
      transform: rotateZ(360deg);
    }
  }
 
  
  @keyframes floating {
    0% {
      transform: translateY(0px);
    }
  
    50% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(0px);
    }
  }

  @media screen and (max-width: 700px) {
    .booking-card{
      position: relative;
      height: 100vh;
      width: 100vh;
    }

    

  }