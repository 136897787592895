@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:wght@400;600;900&family=Montserrat:wght@400;500;600;700;900&family=Libre+Baskerville:ital@1&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

/*
body {
  position: relative;
  background-image: url("./img/coming-soon.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
body::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.App {
  text-align: center;
}
.App img {
  height: 100px;
  margin-bottom: 3em;
}
.App h1 {
  color: #fff;
  font-size: 4em;
}
.App .socialMedia {
  margin-top: 2em;
  text-align: center;
}

.App .socialMedia a {
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.App .socialMedia a i {
  padding: 20px;
  border-radius: 50%;
  font-size: 1.3em;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.App .socialMedia a i.fa-facebook {
  background-color: #4267b2;
  color: #fff;
}

.App .socialMedia a i.fa-instagram {
  background-color: #f14164;
  color: #fff;
}

.App .socialMedia a i.fa-linkedin {
  background-color: #0077b5;
  color: #fff;
}

.App .socialMedia a i.fa-twitter {
  background-color: #00acee;
  color: #fff;
}

.App .socialMedia a:not(:first-child) {
  margin-left: 1em;
}
*/

/*
@media only screen and (max-width: 992px) {
  .App img {
    height: 100px;
  }
  .App h1 {
    font-size: 2.5em;
  }
  .App .socialMedia a i {
    font-size: 1em;
    padding: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .App img {
    width: 80%;
    height: auto;
  }
}
*/

::selection {
  background-color: #e62b1e;
  color: #fff;
}

.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.lineTitle {
  position: relative;
  font-family: "Montserrat", sans-serif;
  border-left: 4px solid #e62b1e;
  margin-left: 20px;
  font-size: 1.7em;
}

.lineTitle span {
  display: inline-block;
  background-color: #fff;
  padding: 0 10px 0 20px;
}

.lineTitle span strong {
  font-weight: bold;
}

.lineTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  width: 95%;
  border-top: 1px solid #c5c5c5;
  z-index: -1;
}

@media only screen and (min-width: 992px) {
  .lineTitle {
    margin-left: 50px;
  }
}
