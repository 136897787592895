.speakerPopup{
    width: 90%;
    height: inherit;
    border: 2px solid red;
    background: white;
    position: fixed;
    top: 15vh;
    left: 5%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 1rem;
    z-index: 10000;
    /* position: relative; */
}

.speakerPopup > p{
    position: absolute;
    color: red;
    top: 2%;
    right: 2%;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;

}

.speakerPopup img{
    flex: 1;
    border-radius: 7px;
    width: 18rem;
}

.speakerPopupDetails{
    flex: 2;
    height: 60vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 5rem;
    padding-right: 5rem;
}
.speakerPopupDetails div{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2rem;
}

.speakerPopupDetails div span:nth-child(2){
    color: red;
    font-weight: bold;
    font-size: 1.2rem;


}
.speakerPopupDetails span:nth-child(1){
    font-size: 2.5rem;
    font-weight: bold;
}

.speakerPopupDetails > span{
    text-align: justify;
}

@media screen and (max-width: 700px) {
    .speakerPopup{
        width: 80vw;
        overflow-x: hidden;
        flex-direction: column;
        height: 80vh;
        top: 10vh;
        left: 10vw;
    }
    .speakerPopup img{
        width: 100vw;
            overflow-x: hidden;
        margin-top: 9em;
        width: 15rem;
        display: none;
    }
    .speakerPopupDetails div span:nth-child(1){
        font-size: 1.5rem;

    }
    
.speakerPopupDetails{
    flex: 1;
    padding: 1.5rem;
}
    
}