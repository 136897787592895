
.person-card {
  display: flex;

  flex-direction: column;
  position: relative;
  border-radius: 2.5em 0;
  margin: 3em 0;
  width: 300px;
  background: #ebecf0;
  transition: 0.5s all ease;
}

/* .card-1{
    background: 	#D3D3D3; 
  }
  
  .card-2{
    background: #9ACD32;
  }
  
  .card-3{
     background: #48D1CC;
  }
  
  .card-4{
    background: #7B68EE;
  }
  
  .card-5{
    
    background: #FF8C00;
  }
  
  .card-6{
   
     background: yellow;
  }
   */

.person-card:hover {
  transform: scale(1.03);
  filter: none;
  cursor: pointer;
  /*   position: absolute;
        height: 100%;
        width: 100%;
        top: 100%;
        left: 0;
        background-color: rgba(230, 43, 30, 0.7);
        background-color: rgba(43, 30, 230, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: 0.3s all ease; */
}
.card-img {
  display: flex;
  justify-content: center;
  background-image: url("../../../img/card-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-top-left-radius: 2.5rem !important;
  border-bottom-right-radius: 2.5rem !important;
}
.person-name,
.position {
  margin-left: 0.5em;
}

.position {
  line-height: 20px;
}

.person-card img {
  position: relative;
  padding: 0.5em 10px 0;
  height: 300px;
  align-self: flex-end;
}

.card-desc {
  font-weight: bold;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.8;
  width: 100%;
  color: white;
  /* text-align: center; */
  padding-top: 10px;
  line-height: 0.7em;

  /*   padding-left:10px  */
  border-bottom-right-radius: 2.5em;
}
/* hover-text */
/* .person-card:hover{
   opacity: 0.5; position:absolute;
    top: 150%;
    top: 100%;
  /*   width: 100%;
    height: 100%; */
/*   background:red;
  transition: all 1s ease-in-out; } */

.socialsDiv {
  display: none;
  /* align-items: center;
    justify-content: center; */
  position: absolute;
  bottom: -2.5rem;
  right: 4rem;
  margin: auto;
  transition: 0.5s all ease-in-out;
}

.socialsDiv .socials {
  height: 45px;
  /* color: red; */
  /* background: none; */
  /* display: none; */
}

.cardContainer:hover .socialsDiv {
  display: block;
}

.socialsDiv:hover {
  display: flex;
} 

@media screen and (max-width: 670px) {
  .CardContainer {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: auto;
    /* flex-direction: column; */
  }
  .person-card {
    width: 300px;
  }
  .socialsDiv {
    bottom:  -3em;
  }
}

@media screen and (max-width: 780px) {
  .CardContainer {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center !important;
  }
  .person-card {
    width: 300px;
  }
  .socialsDiv {
    bottom: -3em;
  }
}
