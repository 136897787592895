.speakerCard {
    width: 250px;
    height: 314px;
    background: rgb(103, 225, 255);
    background: white;
    transition: all 0.4s;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 8rem;
  }
  .speakerCard img{
    width: 250px;
    height: 314px;
    object-fit: cover;
    border-radius: 7px;
    
  }
  
  .first-content{
  position:relative;
  }
  
  .first-content > div{
    position : absolute;
    bottom:-25%;
    font-size: 1.5rem;
    text-align:center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .first-content > div > span:nth-child(1){
    color:black;
    font-weight: bold;
    font-size: 1.1rem;
}

.first-content > div > span:nth-child(2){
    color: red;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 20px;
}
  
  .speakerCard:hover {
    border-radius: 15px;
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
    background: rgb(103, 151, 255);
    background: white;
  }
  
  .first-content {
    height: 100%;
    width: 100%;
    transition: all 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 15px;
  }
  
  .speakerCard:hover .first-content {
    height: 0px;
    opacity: 0;
  }
  
  .second-content {
    height: 0;
    width: 100%;
    
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    transition: all 0.4s;
    font-size: 0px;
    transform: rotate(90deg) scale(-1);
  }
  
  .second-content span:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: red;
  }
  
  .second-content span:nth-child(2){
    padding: 10px;
    background: reds;
    background: black;
    color: white;
    
    margin-top: 1.5rem;
    font-size: 1rem;
  }
  
  .speakerCard:hover .second-content {
    opacity: 1;
    height: 100%;
    font-size: 1.8rem;
    transform: rotate(0deg);
  }