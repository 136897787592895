body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Flaticon", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Franklin Gothic Medium", "Arial Narrow", sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Sango-Light";
  src: local("Sango-Light"), url("./fonts/Sango-Light.otf") format("truetype");
}

@font-face {
  font-family: "Flaticon";
  src: local("Flaticon"), url("./fonts/Flaticon.ttf") format("truetype");
}
